import {
  UIPanel,
  UIRow,
  UIButton,
  UICheckbox,
  UIText,
  UITextArea,
  UIListbox,
} from "@/components/assets/js/libs/ui.js";

function SidebarIssue(editor) {
  const strings = editor.strings;
  //const mixer = editor.mixer;

  const signals = editor.signals;

  const container = new UIPanel();
  container.setBorderTop("0");
  container.setPaddingTop("20px");
  container.setDisplay("");

  const IssueTitleRow = new UIRow();
  //const objectType = new UIText();

  IssueTitleRow.add(
    new UIText(strings.getKey("sidebar/issue/issuetime")).setClass("Label")
  );
  IssueTitleRow.add(
    new UIText(strings.getKey("sidebar/issue/issuecontents")).setClass("Label")
  );
  //objectTypeRow.add(objectType);
  const Buttoncontain = new UIRow();

  async function issueAdd(text,MarkingCheck) {
    let selobject = editor.selected;
    let markobject = "";
    let issuetime = "";
    let issuecont = "";

    let originmaterial = "";

		if (null === editor.selected || MarkingCheck === false){
			
      await window.myGlobalFunction("마킹 기능 없이 기록됩니다.",1)
			//alert('선택된 오브젝트가 없습니다. 마킹 기능 없이 기록됩니다.');
		}
		else
		{
			if(selobject.name !== "Camera" && null !== editor.selected){	
        
      if (editor.selected !== editor.scene.children[0]){
				markobject = selobject.name;
        
      }
			}
			
		}

    if (window.nowtime !== undefined) {
      issuetime = window.nowtime.toString();
    } else {
      issuetime = "0";
    }
    issuecont = text;

    let userDataObject = editor.scene.children[0].userData;
    let myArr;

    try {
      originmaterial = selobject.material.name;
    } catch (err) {
      console.log("No_Material");
    }

    if (!userDataObject || Object.keys(userDataObject).length === 0) {
      myArr = {
        issue: [],
      };
    } else {
      myArr = userDataObject;
    }

    /*
		if (myArr.issue && myArr.issue.length > 0) {
			myArr.issue[0].name = "업데이트된 이름";  
			myArr.issue[0].age = 5; 
		}
		*/
    let newissue = {
      comp: markobject,
      time: issuetime,
      material: originmaterial,
      contents: issuecont,
    };

    myArr.issue.push(newissue);

    myArr.issue.sort((a, b) => {
      let timeA = parseInt(a.time);
      let timeB = parseInt(b.time);
      return timeA - timeB;
    });

    //let updatedUserDataString = JSON.stringify(myArr);

    editor.scene.children[0].userData = myArr;
    //alert('선택된 오브젝트가 없습니다. 마킹 기능 없이 기록됩니다.');

    editor.signals.sceneGraphChanged.dispatch();

    //alert("추가 되었습니다.");
    
    window.myGlobalFunction("추가 되었습니다.",1)
  }

  window.IssueaddonClick = issueAdd;

  const addButton = new UIButton(strings.getKey("sidebar/issue/issueadd"))
    .setMarginLeft("0px")
    .onClick(() => {
      issueAdd(textInput.getValue(), objectmarker.getValue());
      refreshUI();
    });

  function issueModify(index, text) {
    let userDataObject = editor.scene.children[0].userData.issue;
    userDataObject[index].contents = text;
    //alert("수정 되었습니다.");
    window.myGlobalFunction("수정 되었습니다.",1)
    editor.signals.sceneGraphChanged.dispatch();
  }

  window.IssueModifyonClick = issueModify;

  const saveButton = new UIButton(strings.getKey("sidebar/issue/issuemodify"))
    .setMarginLeft("7px")
    .onClick(() => {
      issueModify(listBox.getSelectedIndex(), textInput.getValue());
      refreshUI();
    });

  const objectmarker = new UICheckbox();
  objectmarker.setValue(true);

  function issueDelete(index) {
    let userDataObject = editor.scene.children[0].userData;

    userDataObject.issue.splice(index, 1);
    editor.scene.children[0].userData = userDataObject;
    //alert("삭제 되었습니다.");
    window.myGlobalFunction("삭제 되었습니다.",1)
  }

  window.IssueDeleteonClick = issueDelete;

  const deleteButton = new UIButton(strings.getKey("sidebar/issue/issuedelete"))
    .setMarginLeft("7px")
    .onClick(() => {
      issueDelete(listBox.getSelectedIndex());
      editor.signals.sceneGraphChanged.dispatch();
      refreshUI();
    });

  Buttoncontain.add(addButton);
  Buttoncontain.add(saveButton);
  Buttoncontain.add(deleteButton);
  Buttoncontain.add(
    new UIText(strings.getKey("sidebar/issue/issuemarker"))
      .setMarginLeft("7px")
      .setClass("Label")
  );
  Buttoncontain.add(objectmarker);
  container.add(Buttoncontain);
  container.add(IssueTitleRow);

  function Issueonclick(index) {
    if (editor.scene.children.length > 0) {
      let firstChild = editor.scene.children[0];
      if (
        firstChild.userData !== undefined &&
        firstChild.userData.issue !== undefined
      ) {
        let userDataObject = editor.scene.children[0].userData.issue;

        //textInput.setValue(userDataObject[listBox.getSelectedIndex()].contents);

        return userDataObject[index].contents;
      }
    }
  }

  window.IssueonClick = Issueonclick;

  const windowcontain = new UIRow();
  //windowcontain.setStyle('resize: vertical;');
  const listBox = new UIListbox();
  listBox.setItems([]);
  listBox.dom.style.marginRight = "20px";
  listBox.dom.style.position = "absolute"; // 절대 위치로 설정
  listBox.dom.style.top = "90px";
  listBox.dom.style.height = "400px";
  listBox.dom.style.width = "100px";
  listBox.onClick(() => {
    textInput.setValue(Issueonclick(listBox.getSelectedIndex()));
  });

  function issueondblclick(index) {
    if (editor.scene.children.length === 0) {
      console.error("editor.scene.children가 비어있습니다.");
      return;
    }

    let userDataObject = editor.scene.children[0].userData
      ? editor.scene.children[0].userData.issue
      : null;

    if (!userDataObject) {
      console.error("userData가 존재하지 않습니다.");
      return;
    }

    let selectedItem = userDataObject[index];

    if (!selectedItem) {
      console.error("선택된 항목이 존재하지 않습니다.");
      return;
    }


    window.stoponClick();

    var mixerTimeSlider = document.getElementById("TimeSlider");
    if (mixerTimeSlider != null) {
      mixerTimeSlider.value = (selectedItem.time / window.timerange) * 100;

      var event = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      mixerTimeSlider.dispatchEvent(event);
    }

    



    setTimeout(() => {
      if (selectedItem.comp !== "") {
        let compobj = editor.scene.getObjectByName(selectedItem.comp);
        if (compobj !== undefined) {
          editor.select(compobj);
          editor.focus(compobj);
          compobj.material = editor.getMaterialByName("red");
        }
      }
    }, 100);
  }

  window.IssueondblClick = issueondblclick;

  listBox.dom.ondblclick = issueondblclick(listBox.getSelectedIndex());

  const textInput = new UITextArea();
  textInput.id = "textInput";
  textInput.dom.style.height = "400px";
  textInput.dom.style.width = "100%";
  textInput.dom.style.marginLeft = "110px";
  textInput.dom.style.marginTop = "-8px";

  windowcontain.add(listBox);
  windowcontain.add(textInput);
  //container.add(windowcontain);

  signals.editorCleared.add(refreshUI);

  //signals.sceneGraphChanged.add(refreshUI);

  //signals.refreshSidebarEnvironment.add(refreshUI);

  function refreshUI() {
    if (editor.scene.children[0] !== undefined) {
      let userDataObject = editor.scene.children[0].userData;
      let items = [];
      let timescape = "";
      if (
        userDataObject &&
        userDataObject.issue &&
        userDataObject.issue.length > 0
      ) {
        for (let i = 0; i < userDataObject.issue.length; i++) {
          timescape =
            Math.floor(userDataObject.issue[i].time / 3600).toString() +
            ":" +
            Math.floor((userDataObject.issue[i].time % 3600) / 60).toString() +
            ":" +
            Math.floor(userDataObject.issue[i].time % 60).toString();

          items.push({ id: i.toString(), name: timescape });
        }
        listBox.setItems(items);
      }
    } else {
      listBox.clear();
    }
    try {
      window.refreshissue();
    } catch (e) {
      //console.log("");
    }
  }

  function winrefreshUI() {
    if (editor.scene.children[0] !== undefined) {
      let userDataObject = editor.scene.children[0].userData;
      let items = [];
      let timescape = "";
      if (
        userDataObject &&
        userDataObject.issue &&
        userDataObject.issue.length > 0
      ) {
        for (let i = 0; i < userDataObject.issue.length; i++) {
          timescape =
            Math.floor(userDataObject.issue[i].time / 3600).toString() +
            ":" +
            Math.floor((userDataObject.issue[i].time % 3600) / 60).toString() +
            ":" +
            Math.floor(userDataObject.issue[i].time % 60).toString();

          items.push({ id: i.toString(), name: timescape });
        }
      }
      /*
      if (editor.config.getKey("autosave") === true) {
        editor.storage.set(editor.toJSON());
      }
      */
      return items;
    }
    return [];
  }
  window.winrefreshUIonClick = winrefreshUI;

  return container;
}

export { SidebarIssue };
