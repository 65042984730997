import * as THREE from "three";

import { TGALoader } from "./loaders/TGALoader.js";
import { AddObjectCommand } from "./commands/AddObjectCommand.js";
import { SetSceneCommand } from "./commands/SetSceneCommand.js";
import { LoaderUtils } from "./LoaderUtils.js";

function Loader(editor) {
  const scope = this;
  this.texturePath = "";

  this.loadItemList = function (items) {
    window.showLoadingScreen(); // 로딩 창 표시
    LoaderUtils.getFilesFromItemList(items, async function (files, filesMap) {
      await scope.loadFiles(files, filesMap);
    });
  };

  this.loadFiles = async function (files, filesMap) {
    if (files.length > 0) {
      filesMap = filesMap || LoaderUtils.createFilesMap(files);

      const manager = new THREE.LoadingManager();
      manager.setURLModifier(function (url) {
        url = url.replace(/^(\.?\/)/, ""); // remove './'
        const file = filesMap[url];
        if (file) {
          console.log("Loading", url);
          return URL.createObjectURL(file);
        }
        return url;
      });

      manager.addHandler(/\.tga$/i, new TGALoader());

      // 파일 로딩 프로세스 진행상황 업데이트
      await runFileLoadingProcess(files, manager);

      window.hideLoadingScreen(); // 로딩 창 숨기기
      window.RefreshWorldMaterial(); // 로딩 완료 후 호출
    }
  };

  async function runFileLoadingProcess(files, manager) {
    let loadedFiles = 0;
    const totalFiles = files.length;

    for (let i = 0; i < files.length; i++) {
      await new Promise((resolve) => {
        scope.loadFile(files[i], manager, function () {
          loadedFiles++;
          const progress = Math.floor((loadedFiles / totalFiles) * 100) + "%";
          window.updateProgressBar(progress); // 전역 함수 사용
          resolve();
        });
      });
    }
  }

  this.loadFile = function (file, manager, onLoadCallback) {
    const filename = file.name;
    const extension = filename.split(".").pop().toLowerCase();

    const reader = new FileReader();
    reader.addEventListener("progress", function (event) {
      if (event.lengthComputable) {
        const size =
          "(" +
          new Intl.NumberFormat().format(Math.floor(event.total / 1000)) +
          " KB)";
        const progress = Math.floor((event.loaded / event.total) * 100) + "%";
        console.log("Loading", filename, size, progress);
        window.updateProgressBar(progress); // 전역 함수 사용
      }
    });

    reader.addEventListener("loadend", function () {
      onLoadCallback();
    });

    // 파일 확장자에 따른 로딩 처리
    switch (extension) {
      case "fbx": {
        reader.addEventListener(
          "load",
          async function (event) {
            const contents = event.target.result;
            const { FBXLoader } = await import("../js/loaders/FBXLoader.js");
            const loader = new FBXLoader(manager);
            const object = loader.parse(contents);
            editor.execute(new AddObjectCommand(editor, object));
          },
          false
        );
        reader.readAsArrayBuffer(file);
        window.winEnvironmentChanged();
        break;
      }

      case "gltf": {
        reader.addEventListener(
          "load",
          async function (event) {
            const contents = event.target.result;
            const loader = await createGLTFLoader(manager);
            loader.parse(contents, "", function (result) {
              let scene = result.scene;
              if (
                scene.children[0].name.indexOf("gltf") !== -1 &&
                scene.children[0].name.indexOf(".gltf") === -1
              ) {
                scene = scene.children[0];
              }
              scene.name = filename;
              scene.animations.push(...result.animations);
              editor.execute(new AddObjectCommand(editor, scene));
              loader.dracoLoader.dispose();
              loader.ktx2Loader.dispose();
            });
          },
          false
        );
        reader.readAsArrayBuffer(file);
        window.winEnvironmentChanged();
        break;
      }

      case "js":
      case "json": {
        reader.addEventListener(
          "load",
          function (event) {
            const contents = event.target.result;
            if (contents.indexOf("postMessage") !== -1) {
              const blob = new Blob([contents], { type: "text/javascript" });
              const url = URL.createObjectURL(blob);
              const worker = new Worker(url);
              worker.onmessage = function (event) {
                event.data.metadata = { version: 2 };
                handleJSON(event.data);
              };
              worker.postMessage(Date.now());
              return;
            }
            let data;
            try {
              data = JSON.parse(contents);
            } catch (error) {
              alert(error);
              return;
            }
            handleJSON(data);
          },
          false
        );
        reader.readAsText(file);
        break;
      }

      default:
        console.error("Unsupported file format (" + extension + ").");
        break;
    }
  };
  function handleJSON(data) {
    if (data.metadata === undefined) {
      // 2.0
      data.metadata = { type: "Geometry" };
    }
    if (data.metadata.type === undefined) {
      // 3.0
      data.metadata.type = "Geometry";
    }
    if (data.metadata.formatVersion !== undefined) {
      data.metadata.version = data.metadata.formatVersion;
    }
    switch (data.metadata.type.toLowerCase()) {
      case "buffergeometry": {
        const loader = new THREE.BufferGeometryLoader();
        const result = loader.parse(data);
        const mesh = new THREE.Mesh(result);
        editor.execute(new AddObjectCommand(editor, mesh));
        break;
      }
      case "geometry":
        console.error('Loader: "Geometry" is no longer supported.');
        break;
      case "object": {
        const loader = new THREE.ObjectLoader();
        loader.setResourcePath(scope.texturePath);
        loader.parse(data, function (result) {
          if (result.isScene) {
            editor.execute(new SetSceneCommand(editor, result));
          } else {
            editor.execute(new AddObjectCommand(editor, result));
          }
        });
        break;
      }
      case "app":
        editor.fromJSON(data);
        break;
    }
  }

  async function createGLTFLoader(manager) {
    const { GLTFLoader } = await import("./loaders/GLTFLoader.js");
    const { DRACOLoader } = await import("./loaders/DRACOLoader.js");
    const { KTX2Loader } = await import("three/addons/loaders/KTX2Loader.js");
    const { MeshoptDecoder } = await import("./libs/meshopt_decoder.module.js");

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("../examples/jsm/libs/draco/gltf/");

    const ktx2Loader = new KTX2Loader();
    ktx2Loader.setTranscoderPath("../examples/jsm/libs/basis/");

    editor.signals.rendererDetectKTX2Support.dispatch(ktx2Loader);

    const loader = new GLTFLoader(manager);
    loader.setDRACOLoader(dracoLoader);
    loader.setKTX2Loader(ktx2Loader);
    loader.setMeshoptDecoder(MeshoptDecoder);

    return loader;
  }
}

export { Loader };
