<template>
  <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-white p-6 rounded shadow-lg">
      <p class="mb-4">{{ message }}</p>
      <div class="flex justify-end space-x-4">
        <button v-if="mode === 0" @click="confirm" class="bg-red-500 text-white px-4 py-2 rounded">삭제</button>
        <button v-if="mode === 0" @click="cancel" class="bg-gray-300 px-4 py-2 rounded">취소</button>
        <button v-if="mode === 1" @click="cancel" class="bg-blue-500 text-white px-4 py-2 rounded">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      required: true,
    },
    mode: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped>
.bg-red-500 {
  background-color: #ef4444;
}

.bg-white {
  background-color: #ffffff;
}
.bg-gray-300 {
  background-color: #d1d5db;
}
.text-white {
  color: #ffffff;
}
.bg-blue-500 {
  background-color: #3b82f6; /* 확인 버튼 색상 */
}
</style>
