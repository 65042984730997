<template>
  <div class="bento-grid">
    <div class="bg-gray-900 py-12 sm:py-16 rounded-lg">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 class="text-3xl font-bold text-white text-center mb-8">
          Our Products
        </h2>
        <button
          @click="closebentogrid"
          class="absolute text-gray-500 hover:text-red-500 text-3xl"
          style="right: 90px; margin-top: -120px"
        >
          <!-- right 조정 -->
          &times;
        </button>
        <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          <div class="bg-white rounded-lg shadow-lg overflow-hidden">
            <img
              class="w-full h-48 object-cover"
              src="https://via.placeholder.com/300"
              alt="Product 1"
            />
            <div class="p-6">
              <h3 class="text-xl font-semibold text-gray-800">Product 1</h3>
              <p class="mt-2 text-gray-600">Description for product 1.</p>
            </div>
          </div>
          <div class="bg-white rounded-lg shadow-lg overflow-hidden">
            <img
              class="w-full h-48 object-cover"
              src="https://via.placeholder.com/300"
              alt="Product 2"
            />
            <div class="p-6">
              <h3 class="text-xl font-semibold text-gray-800">Product 2</h3>
              <p class="mt-2 text-gray-600">Description for product 2.</p>
            </div>
          </div>
          <div class="bg-white rounded-lg shadow-lg overflow-hidden">
            <img
              class="w-full h-48 object-cover"
              src="https://via.placeholder.com/300"
              alt="Product 3"
            />
            <div class="p-6">
              <h3 class="text-xl font-semibold text-gray-800">Product 3</h3>
              <p class="mt-2 text-gray-600">Description for product 3.</p>
            </div>
          </div>
          <div class="bg-white rounded-lg shadow-lg overflow-hidden">
            <img
              class="w-full h-48 object-cover"
              src="https://via.placeholder.com/300"
              alt="Product 4"
            />
            <div class="p-6">
              <h3 class="text-xl font-semibold text-gray-800">Product 4</h3>
              <p class="mt-2 text-gray-600">Description for product 4.</p>
            </div>
          </div>
          <div class="bg-white rounded-lg shadow-lg overflow-hidden">
            <img
              class="w-full h-48 object-cover"
              src="https://via.placeholder.com/300"
              alt="Product 5"
            />
            <div class="p-6">
              <h3 class="text-xl font-semibold text-gray-800">Product 5</h3>
              <p class="mt-2 text-gray-600">Description for product 5.</p>
            </div>
          </div>
          <div class="bg-white rounded-lg shadow-lg overflow-hidden">
            <img
              class="w-full h-48 object-cover"
              src="https://via.placeholder.com/300"
              alt="Product 6"
            />
            <div class="p-6">
              <h3 class="text-xl font-semibold text-gray-800">Product 6</h3>
              <p class="mt-2 text-gray-600">Description for product 6.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BentoGrid",
  methods: {
    closebentogrid() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.bento-grid {
  margin: 0px 50px 30px 40px;

  opacity: 0.8; /* 전체에 80% 투명도 적용 */
}
/* 추가 스타일이 필요한 경우 여기에 작성합니다. */
</style>
