import { UIBreak, UIButton, UIDiv, UIText, UINumber, UIRow } from '@/components/assets/js/libs/ui.js';
//import { SetValueCommand } from './commands/SetValueCommand.js';
//import { SetPositionCommand } from './commands/SetPositionCommand.js';
//import { SetRotationCommand } from './commands/SetRotationCommand.js';
//import { SetSceneCommand } from './commands/SetSceneCommand.js';

function SidebarObjectAnimation( editor ) {

	const strings = editor.strings;
	//const signals = editor.signals;
	const mixer = editor.mixer;

	/*
	function getButtonText( action ) {

		return action.isRunning()
			? strings.getKey( 'sidebar/animations/stop' )
			: strings.getKey( 'sidebar/animations/play' );

	}
	function Animation( animation, object ) {

		const action = mixer.clipAction( animation, object );

		const container = new UIRow();

		const name = new UIText( animation.name ).setWidth( '200px' );
		container.add( name );

		const button = new UIButton( getButtonText( action ) );
		button.onClick( function () {

			action.isRunning() ? action.stop() : action.play();
			button.setTextContent( getButtonText( action ) );

		} );

		container.add( button );

		return container;

	}

	signals.objectSelected.add( function ( object ) {

		if ( object !== null && object.animations.length > 0 ) {

			animationsList.clear();

			const animations = object.animations;

			for ( const animation of animations ) {

				animationsList.add( new Animation( animation, object ) );

			}

			container.setDisplay( '' );

		} else {

			container.setDisplay( 'none' );

		}

	} );

	signals.objectRemoved.add( function ( object ) {

		if ( object !== null && object.animations.length > 0 ) {

			mixer.uncacheRoot( object );

		}

	} );
	*/
	const container = new UIDiv();
	container.setMarginTop( '20px' );
	container.setDisplay( 'none' );

	container.add( new UIText( strings.getKey( 'sidebar/animations' ) ).setTextTransform( 'uppercase' ) );
	container.add( new UIBreak() );
	container.add(new UIBreak());




	const mixerTimeScaleRow = new UIRow();
	const mixerTimeScaleNumber = new UINumber(1).setWidth('60px').setRange(- 10, 10);
	mixerTimeScaleNumber.onChange(function () {

		mixer.timeScale = mixerTimeScaleNumber.getValue();

	});

	mixerTimeScaleRow.add(new UIText(strings.getKey('sidebar/animations/timescale')).setClass('Label'));
	mixerTimeScaleRow.add(mixerTimeScaleNumber);

	container.add(mixerTimeScaleRow);
	
	const playbuttonRow = new UIRow();
	const playbutton = new UIButton(strings.getKey('sidebar/object/testloadbutton')).setMarginLeft('7px').onClick(function () {
		for ( const object of editor.scene.children){

			if ( object !== null && object.animations.length > 0 ) {
	
	
				const animations = object.animations;
	
				for ( const animation of animations ) {
					
					const action = mixer.clipAction( animation, object );
					action.isRunning() ? action.stop() : action.play();
	
				}
			}

		}



	});
	playbuttonRow.add(new UIText(strings.getKey('sidebar/object/testloadbutton')).setClass('Label'));
	playbuttonRow.add(playbutton);

	container.add(playbutton);


	//const animationsList = new UIDiv();
	//container.add( animationsList );


	return container;

}

export { SidebarObjectAnimation };
