import * as THREE from "three";
import {
  UIPanel,
  UIRow,
  UIInput,
  UIButton,
  UIColor,
  UICheckbox,
  UIInteger,
  UITextArea,
  UIText,
  UINumber,
} from "@/components/assets/js/libs/ui.js";
import { UIBoolean } from "./libs/ui.three.js";

import { SetUuidCommand } from "./commands/SetUuidCommand.js";
import { SetValueCommand } from "./commands/SetValueCommand.js";
import { SetPositionCommand } from "./commands/SetPositionCommand.js";
import { SetRotationCommand } from "./commands/SetRotationCommand.js";
import { SetScaleCommand } from "./commands/SetScaleCommand.js";
import { SetColorCommand } from "./commands/SetColorCommand.js";

import { SidebarObjectAnimation } from "./Sidebar.Object.Animation.js";

//import { Storage } from './Storage.js';

//import { SidebarObjectAnimationAllPlay } from './Sidebar.Object.AnimationAllPlay.js';

function SidebarObject(editor) {
  const strings = editor.strings;

  const signals = editor.signals;

  const container = new UIPanel();
  container.setBorderTop("0");
  container.setPaddingTop("20px");
  container.setDisplay("none");

  // Actions

  /*
	let objectActions = new UI.Select().setPosition( 'absolute' ).setRight( '8px' ).setFontSize( '11px' );
	objectActions.setOptions( {

		'Actions': 'Actions',
		'Reset Position': 'Reset Position',
		'Reset Rotation': 'Reset Rotation',
		'Reset Scale': 'Reset Scale'

	} );
	objectActions.onClick( function ( event ) {

		event.stopPropagation(); // Avoid panel collapsing

	} );
	objectActions.onChange( function ( event ) {

		let object = editor.selected;

		switch ( this.getValue() ) {

			case 'Reset Position':
				editor.execute( new SetPositionCommand( editor, object, new Vector3( 0, 0, 0 ) ) );
				break;

			case 'Reset Rotation':
				editor.execute( new SetRotationCommand( editor, object, new Euler( 0, 0, 0 ) ) );
				break;

			case 'Reset Scale':
				editor.execute( new SetScaleCommand( editor, object, new Vector3( 1, 1, 1 ) ) );
				break;

		}

		this.setValue( 'Actions' );

	} );
	container.addStatic( objectActions );
	*/

  // type

  const objectTypeRow = new UIRow();
  const objectType = new UIText();

  objectTypeRow.add(
    new UIText(strings.getKey("sidebar/object/type")).setClass("Label")
  );
  objectTypeRow.add(objectType);

  container.add(objectTypeRow);

  // uuid

  const objectUUIDRow = new UIRow();
  const objectUUID = new UIInput()
    .setWidth("102px")
    .setFontSize("12px")
    .setDisabled(true);
  const objectUUIDRenew = new UIButton(strings.getKey("sidebar/object/new"))
    .setMarginLeft("7px")
    .onClick(function () {
      objectUUID.setValue(THREE.MathUtils.generateUUID());

      editor.execute(
        new SetUuidCommand(editor, editor.selected, objectUUID.getValue())
      );
    });

  objectUUIDRow.add(
    new UIText(strings.getKey("sidebar/object/uuid")).setClass("Label")
  );
  objectUUIDRow.add(objectUUID);
  objectUUIDRow.add(objectUUIDRenew);

  container.add(objectUUIDRow);

  // name

  const objectNameRow = new UIRow();
  const objectName = new UIInput()
    .setWidth("150px")
    .setFontSize("12px")
    .onChange(function () {
      editor.execute(
        new SetValueCommand(
          editor,
          editor.selected,
          "name",
          objectName.getValue()
        )
      );
    });

  objectNameRow.add(
    new UIText(strings.getKey("sidebar/object/name")).setClass("Label")
  );
  objectNameRow.add(objectName);

  container.add(objectNameRow);

  // position

  const objectPositionRow = new UIRow();
  const objectPositionX = new UINumber()
    .setPrecision(3)
    .setWidth("50px")
    .onChange(update);
  const objectPositionY = new UINumber()
    .setPrecision(3)
    .setWidth("50px")
    .onChange(update);
  const objectPositionZ = new UINumber()
    .setPrecision(3)
    .setWidth("50px")
    .onChange(update);

  objectPositionRow.add(
    new UIText(strings.getKey("sidebar/object/position")).setClass("Label")
  );
  objectPositionRow.add(objectPositionX, objectPositionY, objectPositionZ);

  //container.add(objectPositionRow);

  // rotation

  const objectRotationRow = new UIRow();
  const objectRotationX = new UINumber()
    .setStep(10)
    .setNudge(0.1)
    .setUnit("°")
    .setWidth("50px")
    .onChange(update);
  const objectRotationY = new UINumber()
    .setStep(10)
    .setNudge(0.1)
    .setUnit("°")
    .setWidth("50px")
    .onChange(update);
  const objectRotationZ = new UINumber()
    .setStep(10)
    .setNudge(0.1)
    .setUnit("°")
    .setWidth("50px")
    .onChange(update);

  objectRotationRow.add(
    new UIText(strings.getKey("sidebar/object/rotation")).setClass("Label")
  );
  objectRotationRow.add(objectRotationX, objectRotationY, objectRotationZ);

  //container.add(objectRotationRow);

  // scale

  const objectScaleRow = new UIRow();
  const objectScaleX = new UINumber(1)
    .setPrecision(3)
    .setWidth("50px")
    .onChange(update);
  const objectScaleY = new UINumber(1)
    .setPrecision(3)
    .setWidth("50px")
    .onChange(update);
  const objectScaleZ = new UINumber(1)
    .setPrecision(3)
    .setWidth("50px")
    .onChange(update);

  objectScaleRow.add(
    new UIText(strings.getKey("sidebar/object/scale")).setClass("Label")
  );
  objectScaleRow.add(objectScaleX, objectScaleY, objectScaleZ);

  //container.add(objectScaleRow);

  // fov

  const objectFovRow = new UIRow();
  const objectFov = new UINumber().onChange(update);

  objectFovRow.add(
    new UIText(strings.getKey("sidebar/object/fov")).setClass("Label")
  );
  objectFovRow.add(objectFov);

  container.add(objectFovRow);

  // left

  const objectLeftRow = new UIRow();
  const objectLeft = new UINumber().onChange(update);

  objectLeftRow.add(
    new UIText(strings.getKey("sidebar/object/left")).setClass("Label")
  );
  objectLeftRow.add(objectLeft);

  container.add(objectLeftRow);

  // right

  const objectRightRow = new UIRow();
  const objectRight = new UINumber().onChange(update);

  objectRightRow.add(
    new UIText(strings.getKey("sidebar/object/right")).setClass("Label")
  );
  objectRightRow.add(objectRight);

  container.add(objectRightRow);

  // top

  const objectTopRow = new UIRow();
  const objectTop = new UINumber().onChange(update);

  objectTopRow.add(
    new UIText(strings.getKey("sidebar/object/top")).setClass("Label")
  );
  objectTopRow.add(objectTop);

  container.add(objectTopRow);

  // bottom

  const objectBottomRow = new UIRow();
  const objectBottom = new UINumber().onChange(update);

  objectBottomRow.add(
    new UIText(strings.getKey("sidebar/object/bottom")).setClass("Label")
  );
  objectBottomRow.add(objectBottom);

  container.add(objectBottomRow);

  // near

  const objectNearRow = new UIRow();
  const objectNear = new UINumber().onChange(update);

  objectNearRow.add(
    new UIText(strings.getKey("sidebar/object/near")).setClass("Label")
  );
  objectNearRow.add(objectNear);

  container.add(objectNearRow);

  // far

  const objectFarRow = new UIRow();
  const objectFar = new UINumber().onChange(update);

  objectFarRow.add(
    new UIText(strings.getKey("sidebar/object/far")).setClass("Label")
  );
  objectFarRow.add(objectFar);

  container.add(objectFarRow);

  // intensity

  const objectIntensityRow = new UIRow();
  const objectIntensity = new UINumber().onChange(update);

  objectIntensityRow.add(
    new UIText(strings.getKey("sidebar/object/intensity")).setClass("Label")
  );
  objectIntensityRow.add(objectIntensity);

  container.add(objectIntensityRow);

  // color

  const objectColorRow = new UIRow();
  const objectColor = new UIColor().onInput(update);

  objectColorRow.add(
    new UIText(strings.getKey("sidebar/object/color")).setClass("Label")
  );
  objectColorRow.add(objectColor);

  container.add(objectColorRow);

  // ground color

  const objectGroundColorRow = new UIRow();
  const objectGroundColor = new UIColor().onInput(update);

  objectGroundColorRow.add(
    new UIText(strings.getKey("sidebar/object/groundcolor")).setClass("Label")
  );
  objectGroundColorRow.add(objectGroundColor);

  container.add(objectGroundColorRow);

  // distance

  const objectDistanceRow = new UIRow();
  const objectDistance = new UINumber().setRange(0, Infinity).onChange(update);

  objectDistanceRow.add(
    new UIText(strings.getKey("sidebar/object/distance")).setClass("Label")
  );
  objectDistanceRow.add(objectDistance);

  container.add(objectDistanceRow);

  // angle

  const objectAngleRow = new UIRow();
  const objectAngle = new UINumber()
    .setPrecision(3)
    .setRange(0, Math.PI / 2)
    .onChange(update);

  objectAngleRow.add(
    new UIText(strings.getKey("sidebar/object/angle")).setClass("Label")
  );
  objectAngleRow.add(objectAngle);

  container.add(objectAngleRow);

  // penumbra

  const objectPenumbraRow = new UIRow();
  const objectPenumbra = new UINumber().setRange(0, 1).onChange(update);

  objectPenumbraRow.add(
    new UIText(strings.getKey("sidebar/object/penumbra")).setClass("Label")
  );
  objectPenumbraRow.add(objectPenumbra);

  container.add(objectPenumbraRow);

  // decay

  const objectDecayRow = new UIRow();
  const objectDecay = new UINumber().setRange(0, Infinity).onChange(update);

  objectDecayRow.add(
    new UIText(strings.getKey("sidebar/object/decay")).setClass("Label")
  );
  objectDecayRow.add(objectDecay);

  container.add(objectDecayRow);

  // shadow

  const objectShadowRow = new UIRow();

  objectShadowRow.add(
    new UIText(strings.getKey("sidebar/object/shadow")).setClass("Label")
  );

  const objectCastShadow = new UIBoolean(
    false,
    strings.getKey("sidebar/object/cast")
  ).onChange(update);
  objectShadowRow.add(objectCastShadow);

  const objectReceiveShadow = new UIBoolean(
    false,
    strings.getKey("sidebar/object/receive")
  ).onChange(update);
  objectShadowRow.add(objectReceiveShadow);

  container.add(objectShadowRow);

  // shadow bias

  const objectShadowBiasRow = new UIRow();

  objectShadowBiasRow.add(
    new UIText(strings.getKey("sidebar/object/shadowBias")).setClass("Label")
  );

  const objectShadowBias = new UINumber(0)
    .setPrecision(5)
    .setStep(0.0001)
    .setNudge(0.00001)
    .onChange(update);
  objectShadowBiasRow.add(objectShadowBias);

  container.add(objectShadowBiasRow);

  // shadow normal offset

  const objectShadowNormalBiasRow = new UIRow();

  objectShadowNormalBiasRow.add(
    new UIText(strings.getKey("sidebar/object/shadowNormalBias")).setClass(
      "Label"
    )
  );

  const objectShadowNormalBias = new UINumber(0).onChange(update);
  objectShadowNormalBiasRow.add(objectShadowNormalBias);

  container.add(objectShadowNormalBiasRow);

  // shadow radius

  const objectShadowRadiusRow = new UIRow();

  objectShadowRadiusRow.add(
    new UIText(strings.getKey("sidebar/object/shadowRadius")).setClass("Label")
  );

  const objectShadowRadius = new UINumber(1).onChange(update);
  objectShadowRadiusRow.add(objectShadowRadius);

  container.add(objectShadowRadiusRow);

  // visible

  const objectVisibleRow = new UIRow();
  const objectVisible = new UICheckbox().onChange(update);

  objectVisibleRow.add(
    new UIText(strings.getKey("sidebar/object/visible")).setClass("Label")
  );
  objectVisibleRow.add(objectVisible);

  container.add(objectVisibleRow);

  // frustumCulled

  const objectFrustumCulledRow = new UIRow();
  const objectFrustumCulled = new UICheckbox().onChange(update);

  objectFrustumCulledRow.add(
    new UIText(strings.getKey("sidebar/object/frustumcull")).setClass("Label")
  );
  objectFrustumCulledRow.add(objectFrustumCulled);

  container.add(objectFrustumCulledRow);

  // renderOrder

  const objectRenderOrderRow = new UIRow();
  const objectRenderOrder = new UIInteger().setWidth("50px").onChange(update);

  objectRenderOrderRow.add(
    new UIText(strings.getKey("sidebar/object/renderorder")).setClass("Label")
  );
  objectRenderOrderRow.add(objectRenderOrder);

  container.add(objectRenderOrderRow);

  // user data

  const objectUserDataRow = new UIRow();
  const objectUserData = new UITextArea()
    .setWidth("150px")
    .setHeight("40px")
    .setFontSize("12px")
    .onChange(update);
  objectUserData.onKeyUp(function () {
    try {
      JSON.parse(objectUserData.getValue());

      objectUserData.dom.classList.add("success");
      objectUserData.dom.classList.remove("fail");
    } catch (error) {
      objectUserData.dom.classList.remove("success");
      objectUserData.dom.classList.add("fail");
    }
  });

  objectUserDataRow.add(
    new UIText(strings.getKey("sidebar/object/userdata")).setClass("Label")
  );
  objectUserDataRow.add(objectUserData);

  //container.add(objectUserDataRow);

  const TestvueButtonRow = new UIRow();
  const TestvueButton = new UIButton(
    strings.getKey("sidebar/object/testloadbutton")
  )
    .setMarginLeft("7px")
    .onClick(function () {
      alert(editor.selected.name);
      //alert(editor.scene.children[0].name);
      /*
		const targetObject = editor.scene.getObjectByName('box')
		alert(targetObject.name);
		*/

      //TCPSocket();

      /*


		//editor.scene.children[0].rotateX(30.0 * THREE.MathUtils.DEG2RAD);




		const PositionX = 30.0;
		const PositionY = 30.0;
		const PositionZ = 30.0;
		const newPosition = new THREE.Vector3(PositionX, PositionY, PositionZ);

		const RotationX = 30.0;
		const RotationY = 0.0;
		const RotationZ = 0.0;
		const newRotation = new THREE.Euler(RotationX * THREE.MathUtils.DEG2RAD, RotationY * THREE.MathUtils.DEG2RAD, RotationZ * THREE.MathUtils.DEG2RAD);


		//editor.execute(new SetSceneCommand(editor, editor.scene));

		editor.execute(new SetPositionCommand(editor, editor.scene.children[0], newPosition));
		editor.execute(new SetRotationCommand(editor, editor.scene.children[0], newRotation));



		*/

      /*
		
		for (let i = 0; i < editor.scene.children; i++) {
			alert(editor.scene.children[i]);
		}



		*/
      /*
		let i = 0;
		while (i < 5) {

			alert(i);
			i += 1;
		}
		*/
    });

  TestvueButton.id = "TestvueButton";
  TestvueButtonRow.add(
    new UIText(strings.getKey("sidebar/object/testloadbutton")).setClass(
      "Label"
    )
  );
  TestvueButtonRow.add(TestvueButton);
  //container.add(TestvueButtonRow);

  /////////////////////////////////////////////////////////////////////
  const TestFBXButtonRow = new UIRow();
  const TestFBXButton = new UIButton(
    strings.getKey("sidebar/object/testloadbutton")
  )
    .setMarginLeft("7px")
    .onClick(function () {
      //alert(editor.selected.name);
      //alert(editor.scene.children[0].name);
      /*
		const targetObject = editor.scene.getObjectByName('box')
		alert(targetObject.name);
		*/

      const a = Object(
        "https://www.stni.co.kr/three/editor/examples/SQL Example.fbx"
      );
      a.name = "https://www.stni.co.kr/three/editor/examples/SQL Example.fbx";
      //const demofile = new File(["https://www.stni.co.kr/three/editor/examples/SQL Example.fbx"], "https://www.stni.co.kr/three/editor/examples/SQL Example.fbx")
      const convertURLtoFile = (url) => {
        return fetch(url)
          .then((response) => response.blob())
          .then((data) => {
            //const ext = url.split(".").pop(); // 파일 확장자 추출
            const filename = url.split("/").pop(); // 파일 이름 추출
            const metadata = { type: "application/octet-stream" }; // FBX 파일의 MIME 타입
            return new File([data], filename, metadata);
          });
      };

      const url =
        "https://www.stni.co.kr/three/editor/examples/SQL Example.fbx";
      //const url = 'https://www.stni.co.kr/three/editor/examples/testa.fbx';

      convertURLtoFile(url)
        .then((fbxFile) => {
          editor.loader.loadFile(fbxFile);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      onEnvironmentChanged();

      //TCPSocket();

      /*


		//editor.scene.children[0].rotateX(30.0 * THREE.MathUtils.DEG2RAD);




		const PositionX = 30.0;
		const PositionY = 30.0;
		const PositionZ = 30.0;
		const newPosition = new THREE.Vector3(PositionX, PositionY, PositionZ);

		const RotationX = 30.0;
		const RotationY = 0.0;
		const RotationZ = 0.0;
		const newRotation = new THREE.Euler(RotationX * THREE.MathUtils.DEG2RAD, RotationY * THREE.MathUtils.DEG2RAD, RotationZ * THREE.MathUtils.DEG2RAD);


		//editor.execute(new SetSceneCommand(editor, editor.scene));

		editor.execute(new SetPositionCommand(editor, editor.scene.children[0], newPosition));
		editor.execute(new SetRotationCommand(editor, editor.scene.children[0], newRotation));



		*/

      /*
		
		for (let i = 0; i < editor.scene.children; i++) {
			alert(editor.scene.children[i]);
		}



		*/
      /*
		let i = 0;
		while (i < 5) {

			alert(i);
			i += 1;
		}
		*/
    });

  function onEnvironmentChanged() {
    signals.sceneEnvironmentChanged.dispatch("ModelViewer", "ModelViewer");
  }

  window.winEnvironmentChanged = function() {

    signals.sceneEnvironmentChanged.dispatch("ModelViewer", "ModelViewer");
  };

  TestFBXButtonRow.add(
    new UIText(strings.getKey("sidebar/object/testloadbutton")).setClass(
      "Label"
    )
  );
  TestFBXButtonRow.add(TestFBXButton);

  //container.add(TestFBXButtonRow);

  /////////////////////////////////////////////////////////////////////
  var counter = 0;
  /////////////////////////////////////////////////////////////////////
  const TestsqlButtonRow = new UIRow();
  const TestsqlButton = new UIButton(
    strings.getKey("sidebar/object/testsqlbutton")
  )
    .setMarginLeft("7px")
    .onClick(function () {
      if (counter == 0) {
        alert("통신을 시작합니다.");
        SQLConnect();
        counter = 1;
      } else {
        alert("통신이 진행중입니다.");
      }
    });

  TestsqlButtonRow.add(
    new UIText(strings.getKey("sidebar/object/testsqlbutton")).setClass("Label")
  );
  TestsqlButtonRow.add(TestsqlButton);

  //container.add(TestsqlButtonRow);

  /////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  var timerId;
  const StopsqlButtonRow = new UIRow();
  const StopsqlButton = new UIButton(
    strings.getKey("sidebar/object/stopsqlbutton")
  )
    .setMarginLeft("7px")
    .onClick(function () {
      if (counter == 1) {
        stopFetchingData();
        alert("통신을 종료합니다.");
        counter = 0;
      } else {
        alert("진행중인 통신이 없습니다.");
      }
    });

  StopsqlButtonRow.add(
    new UIText(strings.getKey("sidebar/object/stopsqlbutton")).setClass("Label")
  );
  StopsqlButtonRow.add(StopsqlButton);

  //container.add(StopsqlButtonRow);

  /////////////////////////////////////////////////////////////////////

  // Export JSON

  const exportJson = new UIButton(strings.getKey("sidebar/object/export"));
  exportJson.setMarginLeft("120px");
  exportJson.onClick(function () {
    const object = editor.selected;

    let output = object.toJSON();

    try {
      output = JSON.stringify(output, null, "\t");
      output = output.replace(/[\n\t]+([\d.e\-[\]]+)/g, "$1");
    } catch (e) {
      output = JSON.stringify(output);
    }

    const left = (screen.width - 500) / 2;
    const top = (screen.height - 500) / 2;

    const url = URL.createObjectURL(new Blob([output], { type: "text/plain" }));
    window.open(
      url,
      "_blank",
      `location=no,left=${left},top=${top},width=500,height=500`
    );
  });
  //container.add(exportJson);

  // Animations
  //container.add(new SidebarObjectAnimationAllPlay(editor));

  container.add(new SidebarObjectAnimation(editor));

  function stopFetchingData() {
    clearInterval(timerId); // setInterval로 설정한 타이머 제거
  }
  function SQLConnect() {
    timerId = setInterval(function () {
      var xhr = new XMLHttpRequest();
      var responseData;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            responseData = xhr.responseText; // 서버로부터 받은 데이터
            const newa = responseData.replace("[", "");
            const newb = newa.replace("]", "");
            const sqldata = newb.split(",");

            const PositionX = -sqldata[1] / 1000;
            const PositionY = sqldata[3] / 1000;
            const PositionZ = sqldata[2] / 1000;
            const newPosition = new THREE.Vector3(
              PositionX,
              PositionY,
              PositionZ
            );

            const RotationX = sqldata[4] - 90.0;
            const RotationY = sqldata[5];
            const RotationZ = sqldata[6];
            const newRotation = new THREE.Euler(
              RotationX * THREE.MathUtils.DEG2RAD,
              RotationY * THREE.MathUtils.DEG2RAD,
              RotationZ * THREE.MathUtils.DEG2RAD
            );

            //editor.execute(new SetSceneCommand(editor, editor.scene));

            editor.execute(
              new SetPositionCommand(
                editor,
                editor.scene.getObjectByName("FM1500_AGV_modular_3_MR3F3_00Vn"),
                newPosition
              )
            );
            editor.execute(
              new SetRotationCommand(
                editor,
                editor.scene.getObjectByName("FM1500_AGV_modular_3_MR3F3_00Vn"),
                newRotation
              )
            );

            //console.log(responseData); // 데이터 출력 또는 처리
          } else {
            console.error("Request failed: " + xhr.status);
          }
        }
      };

      xhr.open("GET", "sql.php?num=", true);

      xhr.send();
    }, 17);
  }
  //

  function update() {
    const object = editor.selected;

    if (object !== null) {
      const newPosition = new THREE.Vector3(
        objectPositionX.getValue(),
        objectPositionY.getValue(),
        objectPositionZ.getValue()
      );
      if (object.position.distanceTo(newPosition) >= 0.01) {
        editor.execute(new SetPositionCommand(editor, object, newPosition));
      }

      const newRotation = new THREE.Euler(
        objectRotationX.getValue() * THREE.MathUtils.DEG2RAD,
        objectRotationY.getValue() * THREE.MathUtils.DEG2RAD,
        objectRotationZ.getValue() * THREE.MathUtils.DEG2RAD
      );
      if (
        new THREE.Vector3()
          .setFromEuler(object.rotation)
          .distanceTo(new THREE.Vector3().setFromEuler(newRotation)) >= 0.01
      ) {
        editor.execute(new SetRotationCommand(editor, object, newRotation));
      }

      const newScale = new THREE.Vector3(
        objectScaleX.getValue(),
        objectScaleY.getValue(),
        objectScaleZ.getValue()
      );
      if (object.scale.distanceTo(newScale) >= 0.01) {
        editor.execute(new SetScaleCommand(editor, object, newScale));
      }

      if (
        object.fov !== undefined &&
        Math.abs(object.fov - objectFov.getValue()) >= 0.01
      ) {
        editor.execute(
          new SetValueCommand(editor, object, "fov", objectFov.getValue())
        );
        object.updateProjectionMatrix();
      }

      if (
        object.left !== undefined &&
        Math.abs(object.left - objectLeft.getValue()) >= 0.01
      ) {
        editor.execute(
          new SetValueCommand(editor, object, "left", objectLeft.getValue())
        );
        object.updateProjectionMatrix();
      }

      if (
        object.right !== undefined &&
        Math.abs(object.right - objectRight.getValue()) >= 0.01
      ) {
        editor.execute(
          new SetValueCommand(editor, object, "right", objectRight.getValue())
        );
        object.updateProjectionMatrix();
      }

      if (
        object.top !== undefined &&
        Math.abs(object.top - objectTop.getValue()) >= 0.01
      ) {
        editor.execute(
          new SetValueCommand(editor, object, "top", objectTop.getValue())
        );
        object.updateProjectionMatrix();
      }

      if (
        object.bottom !== undefined &&
        Math.abs(object.bottom - objectBottom.getValue()) >= 0.01
      ) {
        editor.execute(
          new SetValueCommand(editor, object, "bottom", objectBottom.getValue())
        );
        object.updateProjectionMatrix();
      }

      if (
        object.near !== undefined &&
        Math.abs(object.near - objectNear.getValue()) >= 0.01
      ) {
        editor.execute(
          new SetValueCommand(editor, object, "near", objectNear.getValue())
        );
        if (object.isOrthographicCamera) {
          object.updateProjectionMatrix();
        }
      }

      if (
        object.far !== undefined &&
        Math.abs(object.far - objectFar.getValue()) >= 0.01
      ) {
        editor.execute(
          new SetValueCommand(editor, object, "far", objectFar.getValue())
        );
        if (object.isOrthographicCamera) {
          object.updateProjectionMatrix();
        }
      }

      if (
        object.intensity !== undefined &&
        Math.abs(object.intensity - objectIntensity.getValue()) >= 0.01
      ) {
        editor.execute(
          new SetValueCommand(
            editor,
            object,
            "intensity",
            objectIntensity.getValue()
          )
        );
      }

      if (
        object.color !== undefined &&
        object.color.getHex() !== objectColor.getHexValue()
      ) {
        editor.execute(
          new SetColorCommand(
            editor,
            object,
            "color",
            objectColor.getHexValue()
          )
        );
      }

      if (
        object.groundColor !== undefined &&
        object.groundColor.getHex() !== objectGroundColor.getHexValue()
      ) {
        editor.execute(
          new SetColorCommand(
            editor,
            object,
            "groundColor",
            objectGroundColor.getHexValue()
          )
        );
      }

      if (
        object.distance !== undefined &&
        Math.abs(object.distance - objectDistance.getValue()) >= 0.01
      ) {
        editor.execute(
          new SetValueCommand(
            editor,
            object,
            "distance",
            objectDistance.getValue()
          )
        );
      }

      if (
        object.angle !== undefined &&
        Math.abs(object.angle - objectAngle.getValue()) >= 0.01
      ) {
        editor.execute(
          new SetValueCommand(editor, object, "angle", objectAngle.getValue())
        );
      }

      if (
        object.penumbra !== undefined &&
        Math.abs(object.penumbra - objectPenumbra.getValue()) >= 0.01
      ) {
        editor.execute(
          new SetValueCommand(
            editor,
            object,
            "penumbra",
            objectPenumbra.getValue()
          )
        );
      }

      if (
        object.decay !== undefined &&
        Math.abs(object.decay - objectDecay.getValue()) >= 0.01
      ) {
        editor.execute(
          new SetValueCommand(editor, object, "decay", objectDecay.getValue())
        );
      }

      if (object.visible !== objectVisible.getValue()) {
        editor.execute(
          new SetValueCommand(
            editor,
            object,
            "visible",
            objectVisible.getValue()
          )
        );
      }

      if (object.frustumCulled !== objectFrustumCulled.getValue()) {
        editor.execute(
          new SetValueCommand(
            editor,
            object,
            "frustumCulled",
            objectFrustumCulled.getValue()
          )
        );
      }

      if (object.renderOrder !== objectRenderOrder.getValue()) {
        editor.execute(
          new SetValueCommand(
            editor,
            object,
            "renderOrder",
            objectRenderOrder.getValue()
          )
        );
      }

      if (
        object.castShadow !== undefined &&
        object.castShadow !== objectCastShadow.getValue()
      ) {
        editor.execute(
          new SetValueCommand(
            editor,
            object,
            "castShadow",
            objectCastShadow.getValue()
          )
        );
      }

      if (object.receiveShadow !== objectReceiveShadow.getValue()) {
        if (object.material !== undefined) object.material.needsUpdate = true;
        editor.execute(
          new SetValueCommand(
            editor,
            object,
            "receiveShadow",
            objectReceiveShadow.getValue()
          )
        );
      }

      if (object.shadow !== undefined) {
        if (object.shadow.bias !== objectShadowBias.getValue()) {
          editor.execute(
            new SetValueCommand(
              editor,
              object.shadow,
              "bias",
              objectShadowBias.getValue()
            )
          );
        }

        if (object.shadow.normalBias !== objectShadowNormalBias.getValue()) {
          editor.execute(
            new SetValueCommand(
              editor,
              object.shadow,
              "normalBias",
              objectShadowNormalBias.getValue()
            )
          );
        }

        if (object.shadow.radius !== objectShadowRadius.getValue()) {
          editor.execute(
            new SetValueCommand(
              editor,
              object.shadow,
              "radius",
              objectShadowRadius.getValue()
            )
          );
        }
      }

      try {
        const userData = JSON.parse(objectUserData.getValue());
        if (JSON.stringify(object.userData) != JSON.stringify(userData)) {
          editor.execute(
            new SetValueCommand(editor, object, "userData", userData)
          );
        }
      } catch (exception) {
        console.warn(exception);
      }
    }
  }

  function updateRows(object) {
    const properties = {
      fov: objectFovRow,
      left: objectLeftRow,
      right: objectRightRow,
      top: objectTopRow,
      bottom: objectBottomRow,
      near: objectNearRow,
      far: objectFarRow,
      intensity: objectIntensityRow,
      color: objectColorRow,
      groundColor: objectGroundColorRow,
      distance: objectDistanceRow,
      angle: objectAngleRow,
      penumbra: objectPenumbraRow,
      decay: objectDecayRow,
      castShadow: objectShadowRow,
      receiveShadow: objectReceiveShadow,
      shadow: [
        objectShadowBiasRow,
        objectShadowNormalBiasRow,
        objectShadowRadiusRow,
      ],
    };

    for (const property in properties) {
      const uiElement = properties[property];

      if (Array.isArray(uiElement) === true) {
        for (let i = 0; i < uiElement.length; i++) {
          uiElement[i].setDisplay(object[property] !== undefined ? "" : "none");
        }
      } else {
        uiElement.setDisplay(object[property] !== undefined ? "" : "none");
      }
    }

    //

    if (object.isLight) {
      objectReceiveShadow.setDisplay("none");
    }

    if (object.isAmbientLight || object.isHemisphereLight) {
      objectShadowRow.setDisplay("none");
    }
  }

  function updateTransformRows(object) {
    if (
      object.isLight ||
      (object.isObject3D && object.userData.targetInverse)
    ) {
      objectRotationRow.setDisplay("none");
      objectScaleRow.setDisplay("none");
    } else {
      objectRotationRow.setDisplay("");
      objectScaleRow.setDisplay("");
    }
  }

  // events

  signals.objectSelected.add(function (object) {
    if (object !== null) {
      container.setDisplay("block");

      updateRows(object);
      updateUI(object);
    } else {
      container.setDisplay("none");
    }
  });

  signals.objectChanged.add(function (object) {
    if (object !== editor.selected) return;

    updateUI(object);
  });

  signals.refreshSidebarObject3D.add(function (object) {
    if (object !== editor.selected) return;

    updateUI(object);
  });

  function updateUI(object) {
    objectType.setValue(object.type);

    objectUUID.setValue(object.uuid);
    objectName.setValue(object.name);

    objectPositionX.setValue(object.position.x);
    objectPositionY.setValue(object.position.y);
    objectPositionZ.setValue(object.position.z);

    objectRotationX.setValue(object.rotation.x * THREE.MathUtils.RAD2DEG);
    objectRotationY.setValue(object.rotation.y * THREE.MathUtils.RAD2DEG);
    objectRotationZ.setValue(object.rotation.z * THREE.MathUtils.RAD2DEG);

    objectScaleX.setValue(object.scale.x);
    objectScaleY.setValue(object.scale.y);
    objectScaleZ.setValue(object.scale.z);

    if (object.fov !== undefined) {
      objectFov.setValue(object.fov);
    }

    if (object.left !== undefined) {
      objectLeft.setValue(object.left);
    }

    if (object.right !== undefined) {
      objectRight.setValue(object.right);
    }

    if (object.top !== undefined) {
      objectTop.setValue(object.top);
    }

    if (object.bottom !== undefined) {
      objectBottom.setValue(object.bottom);
    }

    if (object.near !== undefined) {
      objectNear.setValue(object.near);
    }

    if (object.far !== undefined) {
      objectFar.setValue(object.far);
    }

    if (object.intensity !== undefined) {
      objectIntensity.setValue(object.intensity);
    }

    if (object.color !== undefined) {
      objectColor.setHexValue(object.color.getHexString());
    }

    if (object.groundColor !== undefined) {
      objectGroundColor.setHexValue(object.groundColor.getHexString());
    }

    if (object.distance !== undefined) {
      objectDistance.setValue(object.distance);
    }

    if (object.angle !== undefined) {
      objectAngle.setValue(object.angle);
    }

    if (object.penumbra !== undefined) {
      objectPenumbra.setValue(object.penumbra);
    }

    if (object.decay !== undefined) {
      objectDecay.setValue(object.decay);
    }

    if (object.castShadow !== undefined) {
      objectCastShadow.setValue(object.castShadow);
    }

    if (object.receiveShadow !== undefined) {
      objectReceiveShadow.setValue(object.receiveShadow);
    }

    if (object.shadow !== undefined) {
      objectShadowBias.setValue(object.shadow.bias);
      objectShadowNormalBias.setValue(object.shadow.normalBias);
      objectShadowRadius.setValue(object.shadow.radius);
    }

    objectVisible.setValue(object.visible);
    objectFrustumCulled.setValue(object.frustumCulled);
    objectRenderOrder.setValue(object.renderOrder);

    try {
      objectUserData.setValue(JSON.stringify(object.userData, null, "  "));
    } catch (error) {
      console.log(error);
    }

    objectUserData.setBorderColor("transparent");
    objectUserData.setBackgroundColor("");

    updateTransformRows(object);
  }


  async function updatePositionIfNeeded(object, newPosition) {

    //if ((object.position.x !== newPosition.x) || (object.position.y !== newPosition.y) ||(object.position.z !== newPosition.z)) {
    if (!object.position.equals(newPosition)) {
      editor.execute(new SetPositionCommand(editor, object, newPosition));
    }
  }
  async function updateRotationIfNeeded(object, newRotation) {
    if (!object.rotation.equals(newRotation)) {
      editor.execute(new SetRotationCommand(editor, object, newRotation));
    }
  }
  
  ///////////////////////////////////////////////////////////////////

  fetch('example/mapping.txt')
  .then(response => {
    if (!response.ok) {
      throw new Error('파일을 불러오는 중 오류가 발생했습니다.');
    }
    return response.text();
  })
  .then(data => {
    let parsedData = data.split('\r\n');

    const keys = [
      'agv1_x', 'agv1_y', 'agv1_z', 'agv1_l', 'agv1_j1', 'agv1_j2', 'agv1_j3', 'agv1_j4', 'agv1_j5', 'agv1_j6',
      'agv2_x', 'agv2_y', 'agv2_z', 'agv2_l', 'agv2_j1', 'agv2_j2', 'agv2_j3', 'agv2_j4', 'agv2_j5', 'agv2_j6'
    ];

    keys.forEach((key, index) => {
      if (parsedData[index] && parsedData[index].split(':').length > 1) {
        let value = parseFloat(parsedData[index].split(':')[1]);
        window[key] = isNaN(value) ? 0.0 : value;
      } else {
        window[key] = 0.0;
      }

    });
  })
  .catch(error => {
    console.error('파일 처리 중 오류:', error);
    const keys = [
      'agv1_x', 'agv1_y', 'agv1_z', 'agv1_l', 'agv1_j1', 'agv1_j2', 'agv1_j3', 'agv1_j4', 'agv1_j5', 'agv1_j6',
      'agv2_x', 'agv2_y', 'agv2_z', 'agv2_l', 'agv2_j1', 'agv2_j2', 'agv2_j3', 'agv2_j4', 'agv2_j5', 'agv2_j6'
    ];
    keys.forEach(key => {
      window[key] = 0.0;
    });
  });
  ///////////////////////////////////////////////////////////////////

  window.ObjectPostionRenew = async function (
    M_num,
    Tx,
    Ty,
    Rz,
    Height,
    J1,
    J2,
    J3,
    J4,
    J5,
    J6
  ) {
    try {
      let agv, lift, lifta, liftb, jointNames;

      let m_agv_x = M_num === 1 ? window.agv1_x : window.agv2_x;
      let m_agv_y = M_num === 1 ? window.agv1_y : window.agv2_y;
      let m_agv_z = M_num === 1 ? window.agv1_z : window.agv2_z;
      let m_lift = M_num === 1 ? window.agv1_l : window.agv2_l;
      let m_J = M_num === 1 ? [window.agv1_j1, window.agv1_j2, window.agv1_j3, window.agv1_j4, window.agv1_j5, window.agv1_j6] : [window.agv2_j1, window.agv2_j2, window.agv2_j3, window.agv2_j4, window.agv2_j5, window.agv2_j6];

      if (M_num == 1) {
        agv = editor.scene.getObjectByName("Robot_Floor_Track_#2_00Mn");
        lift = editor.scene.getObjectByName("Z_00Sn");
        lifta = editor.scene.getObjectByName("Z_2_00Qn");
        liftb = editor.scene.getObjectByName("Link_1_00Rn");
        jointNames = [
          "Link1_00Vn",
          "Link2_00Wn",
          "Link3_00Xn",
          "Link4_00Yn",
          "Link5_00Zn",
          "Link6_00an"
        ];
      } else if (M_num == 2) {
        agv = editor.scene.getObjectByName("Robot_Floor_Track_002n");
        lift = editor.scene.getObjectByName("Z_008n");
        lifta = editor.scene.getObjectByName("Z_2_006n");
        liftb = editor.scene.getObjectByName("Link_1_007n");
        jointNames = [
          "Link1_00Bn",
          "Link2_00Cn",
          "Link3_00Dn",
          "Link4_00En",
          "Link5_00Fn",
          "Link6_00Gn"
        ];
      } else {
        console.log("Invalid M_num value");
        return;
      }
  
      let newPosition = new THREE.Vector3((Ty+m_agv_x) / 1000, 0.0, (Tx+m_agv_y) / 1000);
      let newRotation = new THREE.Euler(
        -90.0 * THREE.MathUtils.DEG2RAD,
        0.0 * THREE.MathUtils.DEG2RAD,
        (180.0 + Rz+m_agv_z) * THREE.MathUtils.DEG2RAD
      );
      await updatePositionIfNeeded(agv, newPosition);
      await updateRotationIfNeeded(agv, newRotation);

      newPosition = new THREE.Vector3(0.0, 0.0, (Height+m_lift) / 10.0);
      await updatePositionIfNeeded(lift, newPosition);



      newPosition = new THREE.Vector3(0.0, 0.0, (Height+m_lift) / 20.0);
      await updatePositionIfNeeded((lifta), newPosition);
      await updatePositionIfNeeded((liftb), newPosition);

      const jointAngles = [J1 + m_J[0], J2 + m_J[1], J3 + m_J[2], J4 + m_J[3], J5 + m_J[4], J6 + m_J[5]];

  
      for (let index = 0; index < jointNames.length; index++) {
        let joint = editor.scene.getObjectByName(jointNames[index]);
        let newRotation;
  
        if (index === 1 || index === 2) { 
          newRotation = new THREE.Euler(
            joint.rotation.x,
            jointAngles[index] * THREE.MathUtils.DEG2RAD,
            joint.rotation.z
          );
        } else { 
          newRotation = new THREE.Euler(
            joint.rotation.x,
            joint.rotation.y,
            jointAngles[index] * THREE.MathUtils.DEG2RAD
          );
        }
  
        await updateRotationIfNeeded(joint, newRotation);
      }
  
    } catch (error) {
      console.log("Mapping Error:", error);
    }
  };
  
  return container;
}

export { SidebarObject };
