<template>
  <div class="layout">
    <!-- 3d대신 넣은 임시 이미지 -->
    <Three_Viewport/>
    <figure class="image-container">
      <div class="overlay-content">
        <!-- breadcrumb 추가 -->
        <div class="breadcrumb">
          <NavSide
            @toggle-bento-grid="toggleBentoGrid"
            @toggle-issue="toggleIssue"
          />
        </div>
        <MessageModal
          v-if="isModalVisible"
          :isVisible="isModalVisible"
          :message="modalMessage" 
          :mode="messageboxmode" 
          @confirm="handleConfirm"
          @cancel="handleCancel"
        />

        <div class="left-content"></div>
        <div class="right-content">
          <!-- <BreadCrumb></BreadCrumb> -->
          <!-- 상태에 따라 BentoGrid 또는 EmptyComponent 표시 -->
          <BentoGrid v-if="isBentoGridVisible" @close="toggleBentoGrid" />
          <EmptyComponent v-else />
          <!-- 상태에 따라 IssueModal 표시 -->
          <IssueModal v-if="isIssueModalVisible" @close="toggleIssue" />
        </div>
      </div>
    </figure>
  </div>
</template>

<script>
// import BreadCrumb from "@/components/BreadCrumb.vue";
import NavSide from "@/components/NavSide.vue";
import BentoGrid from "@/views/BentoGrid.vue";
import EmptyComponent from "@/components/EmptyComponent";
import IssueModal from "@/components/IssueModal.vue";
import Three_Viewport from "@/components/Three_Viewport.vue";

export default {
  name: "MainLayout",
  components: {
    // BreadCrumb,
    Three_Viewport,
    NavSide,
    BentoGrid,
    EmptyComponent,
    IssueModal,
  },
  data() {
    return {
      isBentoGridVisible: false, //BentoGrid 표시 여부 상태
      isIssueModalVisible: false, // IssueModal 표시 여부 상태
      isModalVisible:false,
      modalMessage: "", 
      modalPromise: null, 
      messageboxmode: 0,
    };
  },
  mounted() {
    window.myGlobalFunction = (message,msgboxmode) => {
      return this.showModal(message,msgboxmode);
    };
  },
  methods: {
    showModal(message,msgboxmode) {
      this.modalMessage = message; 
      this.messageboxmode = msgboxmode;
      this.isModalVisible = true; 
      return new Promise((resolve) => {
        this.modalPromise = resolve;
      });
    },
    handleConfirm() {
      this.isModalVisible = false; 
      this.modalPromise(true); 
    },
    handleCancel() {
      this.isModalVisible = false; 
      this.modalPromise(false); 
    },
    toggleBentoGrid() {
      //console.log("BentoGrid 상태 변경"); // 확인용 로그
      // BentoGrid의 표시 여부를 토글
      this.isBentoGridVisible = !this.isBentoGridVisible;
      if (this.isBentoGridVisible) {
        this.isIssueModalVisible = false;
      }
    },
    toggleIssue() {
      //console.log("이슈 모달 생성"); // 확인용 로그
      // BentoGrid의 표시 여부를 토글
      this.isIssueModalVisible = !this.isIssueModalVisible;
      if (this.isIssueModalVisible) {
        this.isBentoGridVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.layout {
  display: flex;
  flex-direction: left;
  align-items: flex-start;
  width: 100%;
}

.image-container {
  position: relative; /* 자식 요소가 이미지 위에 겹칠 수 있도록 relative로 설정 */
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-image {
  width: 100%;
  height: auto;
  display: block; /* 이미지가 전체 너비를 차지하도록 설정 */
}

.overlay-content {
  position: absolute; /* 이미지 위에 겹치도록 설정 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* 세로 방향으로 쌓이도록 수정 */
  padding: 20px;
}

.breadcrumb {
  margin-bottom: 10px; /* breadcrumb와 아래 요소 간의 간격 조정 */
  color: white; /* 필요에 따라 breadcrumb 텍스트 색상 설정 */
}

.left-content {
  height: 100%;
  width: 75%; /* 왼쪽 컨텐츠의 너비를 조정 */
  display: flex; /* 필요에 따라 flex로 설정 */
  flex-direction: column; /* 세로로 정렬 */
}

.right-content {
  height: 100%;
  width: 100%; /* RectGrid가 절반을 차지 */
}
</style>
