import { Box3, Vector3 } from "three";

import {
  UIPanel,
  UIRow,
  UIHorizontalRule,
} from "@/components/assets/js/libs/ui.js";

import { AddObjectCommand } from "./commands/AddObjectCommand.js";
import { RemoveObjectCommand } from "./commands/RemoveObjectCommand.js";
import { SetPositionCommand } from "./commands/SetPositionCommand.js";
import { clone } from "../../assets/examples/jsm/utils/SkeletonUtils.js";

function MenubarEdit(editor) {
  const strings = editor.strings;

  const container = new UIPanel();
  container.setClass("menu");

  const title = new UIPanel();
  title.setClass("title");
  title.setTextContent(strings.getKey("menubar/edit"));
  container.add(title);

  const options = new UIPanel();
  options.setClass("options");
  container.add(options);
  /*
	// Undo

	const undo = new UIRow();
	undo.setClass( 'option' );
	undo.setTextContent( strings.getKey( 'menubar/edit/undo' ) );
	undo.onClick( function () {

		editor.undo();

	} );
	options.add( undo );

	// Redo

	const redo = new UIRow();
	redo.setClass( 'option' );
	redo.setTextContent( strings.getKey( 'menubar/edit/redo' ) );
	redo.onClick( function () {

		editor.redo();

	} );
	options.add( redo );

	editor.signals.historyChanged.add( function () {

		const history = editor.history;

		undo.setClass( 'option' );
		redo.setClass( 'option' );

		if ( history.undos.length == 0 ) {

			undo.setClass( 'inactive' );

		}

		if ( history.redos.length == 0 ) {

			redo.setClass( 'inactive' );

		}

	} );
	*/
  // ---

  options.add(new UIHorizontalRule());

  // Center

  function editCenter() {
    const object = editor.selected;

    if (object === null || object.parent === null) return; // avoid centering the camera or scene

    const aabb = new Box3().setFromObject(object);
    const center = aabb.getCenter(new Vector3());
    const newPosition = new Vector3();

    newPosition.x = object.position.x + (object.position.x - center.x);
    newPosition.y = object.position.y + (object.position.y - center.y);
    newPosition.z = object.position.z + (object.position.z - center.z);

    editor.execute(new SetPositionCommand(editor, object, newPosition));
  }
  window.editCenteronClick = editCenter;

  let option = new UIRow();
  option.setClass("option");
  option.setTextContent(strings.getKey("menubar/edit/center"));
  option.onClick(() => {
    editCenter();
  });
  //options.add( option );

  // Clone

  function editClone() {
    let object = editor.selected;

    if (object === null || object.parent === null) return; // avoid cloning the camera or scene

    object = clone(object);

    editor.execute(new AddObjectCommand(editor, object));
  }

  window.editCloneonClick = editCenter;

  option = new UIRow();
  option.setClass("option");
  option.setTextContent(strings.getKey("menubar/edit/clone"));
  option.onClick(() => {
    editClone();
  });
  options.add(option);

  // Delete

  function editDelete() {
    const object = editor.selected;

    if (object !== null && object.parent !== null) {
      editor.execute(new RemoveObjectCommand(editor, object));
    }
  }

  window.editDeleteonClick = editDelete;

  option = new UIRow();
  option.setClass("option");
  option.setTextContent(strings.getKey("menubar/edit/delete"));
  option.onClick(() => {
    editDelete;
  });
  options.add(option);

  return container;
}

export { MenubarEdit };
