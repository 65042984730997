import { UIElement } from '@/components/assets/js/libs/ui.js';

function Resizer(editor) {

    const signals = editor.signals;

    const dom = document.createElement('div');
    dom.id = 'resizer';

    function onPointerDown(event) {

        if (event.isPrimary === false) return;

        dom.ownerDocument.addEventListener('pointermove', onPointerMove);
        dom.ownerDocument.addEventListener('pointerup', onPointerUp);

    }

    function onPointerUp(event) {

        if (event.isPrimary === false) return;

        dom.ownerDocument.removeEventListener('pointermove', onPointerMove);
        dom.ownerDocument.removeEventListener('pointerup', onPointerUp);

    }

    function onPointerMove(event) {

        // PointerEvent's movementX/movementY are 0 in WebKit

        if (event.isPrimary === false) return;

        let x;
        if (window.Viewmode === 'Monitoring' || window.Viewmode === 'WebViewer' ) {
            x = 0;
        } else {
            const offsetWidth = document.body.offsetWidth;
            const clientX = event.clientX;

            const cX = clientX < 0 ? 0 : clientX > offsetWidth ? offsetWidth : clientX;

            x = Math.max(340, offsetWidth - cX); // .TabbedPanel min-width: 260px

        }
        dom.style.right = x + 'px';

        document.getElementById('sidebar').style.width = x + 'px';
        document.getElementById('player').style.right = x + 'px';
        //document.getElementById('script').style.right = x + 'px';
        document.getElementById('viewport').style.right = x + 'px';
        //document.getElementById('playerbar').style.right = x + 500 + 'px';
        signals.windowResize.dispatch();

    }
    dom.addEventListener('pointerdown', onPointerDown);
	window.threeViewportResizer = function() {
        signals.windowResize.dispatch();

    }
    
    function initializeStyles() {
        if (window.Viewmode === 'Monitoring' || window.Viewmode === 'WebViewer') {
            const sidebar = document.getElementById('sidebar');
            const player = document.getElementById('player');
            const viewport = document.getElementById('viewport');

            if (sidebar && player && viewport) {
                dom.style.right = '0px';
                sidebar.style.width = '0px';
                player.style.right = '0px';
                viewport.style.right = '0px';
                signals.windowResize.dispatch();
            } else {
                console.error('Some elements are missing in the DOM.');
            }
        }
    }

    window.addEventListener('load', initializeStyles);

    window.addEventListener('resize', function() {
        signals.windowResize.dispatch();
    });


    if (window.Viewmode !== "Monitoring"|| window.Viewmode !== 'WebViewer')
    {
        
        return new UIElement(dom);

    }
    else{
        
        let doma = document.createElement('div');
        doma.id = 'resizer';
        return new UIElement(doma);

    }

}

export { Resizer };
