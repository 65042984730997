<template>
  <div>
    <div ref="viewport"></div>
    <div ref="toolbar"></div>
    <div ref="player"></div>
    <div ref="sidebar"></div>
    <div ref="menubar"></div>
    <div ref="resizer"></div>
  </div>
</template>

<script>
import "./assets/styles/main.css";
import * as THREE from "three";
import { Editor } from "./assets/js/Editor.js";
import { Viewport } from "./assets/js/Viewport.js";
import { Toolbar } from "./assets/js/Toolbar.js";
import { Player } from "./assets/js/Player.js";
import { Sidebar } from "./assets/js/Sidebar.js";
import { Menubar } from "./assets/js/Menubar.js";
import { Resizer } from "./assets/js/Resizer.js";

export default {
  mounted() {
    this.loadScriptsSequentially([
      "./js/libs/codemirror/codemirror.js",
      "./examples/jsm/libs/draco/draco_encoder.js",
      "./js/libs/codemirror/mode/javascript.js",
      "./js/libs/codemirror/mode/glsl.js",
      "./js/libs/esprima.js",
      "./js/libs/jsonlint.js",
      "./js/libs/ffmpeg.min.js",
      "./js/libs/codemirror/addon/dialog.js",
      "./js/libs/codemirror/addon/show-hint.js",
      "./js/libs/codemirror/addon/tern.js",
      "./js/libs/acorn/acorn.js",
      "./js/libs/acorn/acorn_loose.js",
      "./js/libs/acorn/walk.js",
      "./js/libs/ternjs/polyfill.js",
      "./js/libs/ternjs/signal.js",
      "./js/libs/ternjs/tern.js",
      "./js/libs/ternjs/def.js",
      "./js/libs/ternjs/comment.js",
      "./js/libs/ternjs/infer.js",
      "./js/libs/ternjs/doc_comment.js",
      "./js/libs/tern-threejs/threejs.js",
      "./js/libs/signals.min.js",
    ])
      .then(() => {
        window.showLoadingScreen();

        this.initializeEditor();
      })
      .catch((error) => {
        console.error("Script load error:", error);
      });
  },
  methods: {
    loadScript(src, defer = false) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = !defer;
        if (defer) {
          script.defer = true;
        }
        script.onload = () => resolve();
        script.onerror = () =>
          reject(new Error(`Script load error for ${src}`));

        document.head.appendChild(script);
      });
    },
    loadScriptsSequentially(scripts) {
      return scripts.reduce((promise, src) => {
        return promise.then(() => this.loadScript(src));
      }, Promise.resolve());
    },
    initializeEditor() {
      window.Viewmode = "WebViewer";
      const editor = new Editor();
      const viewport = new Viewport(editor);
      const toolbar = new Toolbar(editor);
      const player = new Player(editor);
      const sidebar = new Sidebar(editor);
      const menubar = new Menubar(editor);
      const resizer = new Resizer(editor);
      //WebViewer , Monitoring , WebManual

      this.$nextTick(() => {
        window.editor = editor;
        window.THREE = THREE;
        const container = this.$refs.viewport;

        if (container) {
          container.appendChild(viewport.dom);
          this.$refs.toolbar.appendChild(toolbar.dom);
          this.$refs.player.appendChild(player.dom);
          this.$refs.sidebar.appendChild(sidebar.dom);
          this.$refs.menubar.appendChild(menubar.dom);
          this.$refs.resizer.appendChild(resizer.dom);

          if (window.Viewmode === "Monitoring") {
            editor.storage.clear();
            const filePath = "example/SQL_Example4.2.fbx";

            const convertURLtoFile = (url) => {
              return fetch(url)
                .then((response) => response.blob())
                .then((data) => {
                  const filename = url.split("/").pop();
                  const metadata = { type: "application/octet-stream" };
                  return new File([data], filename, metadata);
                });
            };

            convertURLtoFile(filePath)
              .then((fbxFile) => {
                editor.loader.loadFile(fbxFile);
              })
              .catch((error) => {
                console.error("Error:", error);
              });

            window.setMonitorViewport();
          } else {
            window.hideLoadingScreen();
            editor.storage.init(() => {
              editor.storage.get((state) => {
                if (state !== undefined) {
                  editor.fromJSON(state);
                }

                const selected = editor.config.getKey("selected");
                if (selected !== undefined) {
                  editor.selectByUuid(selected);
                }
              });

              let timeout;
              function saveState() {
                if (editor.config.getKey("autosave") === false) {
                  return;
                }
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                  editor.signals.savingStarted.dispatch();
                  timeout = setTimeout(() => {
                    editor.storage.set(editor.toJSON());
                    editor.signals.savingFinished.dispatch();
                  }, 100);
                }, 1000);
              }
              const signals = editor.signals;
              signals.geometryChanged.add(saveState);
              signals.objectAdded.add(saveState);
              signals.objectChanged.add(saveState);
              signals.objectRemoved.add(saveState);
              signals.materialChanged.add(saveState);
              signals.sceneBackgroundChanged.add(saveState);
              signals.sceneEnvironmentChanged.add(saveState);
              signals.sceneFogChanged.add(saveState);
              signals.sceneGraphChanged.add(saveState);
              signals.historyChanged.add(saveState);
            });
          }

          document.addEventListener("dragover", (event) => {
            event.preventDefault();
            event.dataTransfer.dropEffect = "copy";
            console.log("dragover event detected");
          });

          document.addEventListener("drop", (event) => {
            event.preventDefault();
            if (event.dataTransfer.types[0] === "text/plain") return;
            if (event.dataTransfer.items) {
              editor.loader.loadItemList(event.dataTransfer.items);
            } else {
              editor.loader.loadFiles(event.dataTransfer.files);
            }
            console.log("drop event detected");
          });

          const onWindowResize = () => {
            editor.signals.windowResize.dispatch();
          };
          window.addEventListener("resize", onWindowResize);
          onWindowResize();

          let isLoadingFromHash = false;
          const hash = window.location.hash;
          if (hash.slice(1, 6) === "file=") {
            const file = hash.slice(6);
            if (confirm("Any unsaved data will be lost. Are you sure?")) {
              const loader = new THREE.FileLoader();
              loader.crossOrigin = "";
              loader.load(file, (text) => {
                editor.clear();
                editor.fromJSON(JSON.parse(text));
              });
              isLoadingFromHash = true;
              console.log(isLoadingFromHash);
            }
          }

          if ("serviceWorker" in navigator) {
            try {
              navigator.serviceWorker.register("sw.js");
            } catch (error) {
              console.error("Service worker registration failed:", error);
            }
          }
        } else {
          console.error("Container element not found!");
        }
      });
    },
  },
};

window.showLoadingScreen = function () {
  if (!document.getElementById("loading-screen")) {
    const loadingScreenHtml = `
            <div id="loading-screen" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; background-color: rgba(0, 0, 0, 0.8);">
                <div style="text-align: center; display: flex; flex-direction: column; align-items: center;">
                    <div class="spinner" style="border: 8px solid rgba(255, 255, 255, 0.3); border-top: 8px solid #fff; border-radius: 50%; width: 50px; height: 50px; animation: spin 1s linear infinite; margin-bottom: 20px;"></div>
                    <div class="progress-bar" style="width: 80%; background-color: rgba(255, 255, 255, 0.2); border-radius: 5px; margin-top: 10px; margin-bottom: 10px;">
                        <div class="progress" style="width: 0; height: 10px; background-color: #fff; border-radius: 5px;"></div>
                    </div>
                    <p id="loading-text" style="color: #fff; margin-top: 10px;">Loading 0%</p>
                </div>
            </div>
        `;
    document.body.insertAdjacentHTML("beforeend", loadingScreenHtml);

    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
        `;
    document.head.appendChild(style);
  }
};

window.hideLoadingScreen = function () {
  const loadingScreen = document.getElementById("loading-screen");
  if (loadingScreen) {
    loadingScreen.remove();
  }
};

// Update progress bar
window.updateProgressBar = function (percentage) {
  checkAndShowLoadingScreen();
  const progressBar = document.querySelector(".progress");
  const loadingText = document.getElementById("loading-text");
  if (progressBar) {
    progressBar.style.width = percentage;
  }
  if (loadingText) {
    loadingText.innerText = "Loading " + percentage;
  }
  if (percentage === "100%") {
    loadingText.innerText = "Loading " + percentage;
    setTimeout(function () {
      console.log("Load Complete!");

      window.hideLoadingScreen();
    }, 1000);
  }
};
function checkAndShowLoadingScreen() {
  // loading-screen DOM이 존재하는지 확인
  if (!document.getElementById("loading-screen")) {
    window.showLoadingScreen(); // 없으면 로딩 화면 표시
  }
}
</script>

<style></style>
