import Vue from "vue";
import App from "./App.vue";
import "./assets/css/tailwind.css";
import router from "./router/index.js";
import MessageModal from './components/MessageModal.vue'; // 컴포넌트 경로를 알맞게 수정하세요.

Vue.config.productionTip = false;

// MessageModal을 전역 컴포넌트로 등록
Vue.component('MessageModal', MessageModal);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");


