import { UIPanel, UIRow } from "@/components/assets/js/libs/ui.js";

function MenubarView(editor) {
  const signals = editor.signals;
  const strings = editor.strings;

  const container = new UIPanel();
  container.setClass("menu");

  const title = new UIPanel();
  title.setClass("title");
  title.setTextContent(strings.getKey("menubar/view"));
  container.add(title);

  const options = new UIPanel();
  options.setClass("options");
  container.add(options);

  // Fullscreen

  function fullscreen() {
    if (document.fullscreenElement === null) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }

    // Safari

    if (document.webkitFullscreenElement === null) {
      document.documentElement.webkitRequestFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }

  const optionFullscreen = new UIRow();
  optionFullscreen.setClass("option");
  optionFullscreen.setTextContent(strings.getKey("menubar/view/fullscreen"));
  optionFullscreen.onClick(fullscreen);
  options.add(optionFullscreen);

  window.ViewFullscreenonClick = fullscreen;
  // Background Color Change

  const optionBackgroundColor = new UIRow();
  optionBackgroundColor.setClass("option");
  optionBackgroundColor.setTextContent("Change Background Color");

  const colorInput = document.createElement("input");
  colorInput.type = "color";
  colorInput.style.display = "none";

  colorInput.addEventListener("input", function () {
    const color = colorInput.value;

    window.backgroundColorChange(color);
  });

  window.ViewBackgroundColoronClick = function () {
    colorInput.click();
  };

  optionBackgroundColor.onClick(function () {
    colorInput.click();
  });

  options.add(optionBackgroundColor);
  container.dom.appendChild(colorInput);

  // XR (Work in progress)

  if ("xr" in navigator) {
    if ("offerSession" in navigator.xr) {
      signals.offerXR.dispatch("immersive-ar");
    } else {
      navigator.xr
        .isSessionSupported("immersive-ar")
        .then(function (supported) {
          if (supported) {
            const option = new UIRow();
            option.setClass("option");
            option.setTextContent("AR");
            option.onClick(function () {
              signals.enterXR.dispatch("immersive-ar");
            });
            options.add(option);
          } else {
            navigator.xr
              .isSessionSupported("immersive-vr")
              .then(function (supported) {
                if (supported) {
                  const option = new UIRow();
                  option.setClass("option");
                  option.setTextContent("VR");
                  option.onClick(function () {
                    signals.enterXR.dispatch("immersive-vr");
                  });
                  options.add(option);
                }
              });
          }
        });
    }
  }

  return container;
}

export { MenubarView };
