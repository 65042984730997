import { UITabbedPanel, UISpan } from '@/components/assets/js/libs/ui.js';

import { SidebarScene } from './Sidebar.Scene.js';
import { SidebarProperties } from './Sidebar.Properties.js';
import { SidebarProject } from './Sidebar.Project.js';
import { SidebarSettings } from './Sidebar.Settings.js';

function Sidebar( editor ) {

	const strings = editor.strings;

	const container = new UITabbedPanel();
	container.setId( 'sidebar' );
	if (window.Viewmode === "WebManual")
	{
		container.setTop('60px'); 
	}
	if (window.Viewmode === "WebViewer")
	{
		container.setTop('0px'); 
	}

	const scene = new UISpan().add(
		new SidebarScene( editor ),
		new SidebarProperties( editor )
	);
	// eslint-disable-next-line no-unused-vars
	const project = new SidebarProject( editor );
	const settings = new SidebarSettings( editor );
	container.addTab( 'scene', strings.getKey( 'sidebar/scene' ), scene );
	//container.addTab( 'project', strings.getKey( 'sidebar/project' ), project );
	container.addTab('settings', strings.getKey('sidebar/settings'), settings);
	container.select( 'scene' );

	if(window.Viewmode === 'Monitoring' || window.Viewmode === 'WebViewer')
	{
		container.dom.style.display = 'none'; 
		container.dom.style.visibility = 'hidden'; 
	}


	return container;

}

export { Sidebar };
