import Vue from "vue";
import Router from "vue-router";
import EmptyComponent from "@/components/EmptyComponent.vue"; // 빈 컴포넌트 import
import BentoGrid from "@/views/BentoGrid.vue"; // SettingsComponent 경로

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: EmptyComponent, // 아무것도 표시하지 않음
    },
    {
      path: "/bentogrid",
      name: "BentoGrid",
      component: BentoGrid,
    },
    // 필요에 따라 다른 라우트 추가
  ],
});
