import {
  UIPanel,
  UIRow,
  UIHorizontalRule,
} from "@/components/assets/js/libs/ui.js";

function MenubarFile(editor) {
  const strings = editor.strings;

  const saveString = editor.utils.saveString;

  const container = new UIPanel();
  container.setClass("menu");

  const title = new UIPanel();
  title.setClass("title");
  title.setTextContent(strings.getKey("menubar/file"));
  container.add(title);

  const options = new UIPanel();
  options.setClass("options");
  container.add(options);

  // New

  /*
	let optiona = new UIRow();
	optiona.setClass( 'option' );
	optiona.setTextContent( strings.getKey( 'menubar/file/new' ) );
	optiona.onClick( function () {

		//window.setMonitorViewpora();



	} );
	options.add( optiona );

	*/

  function clearEditor() {
    if (confirm("Any unsaved data will be lost. Are you sure?")) {
      editor.clear();
      try {
        window.refreshissue();
      } catch (err) {
        console.log(err);
      }
    }
  }

  let option = new UIRow();
  option.setClass("option");
  option.setTextContent(strings.getKey("menubar/file/new"));
  option.onClick(() => {
    clearEditor();
  });
  options.add(option);

  window.NewFileonClick = clearEditor;

  //

  options.add(new UIHorizontalRule());

  /*
	// Project Open

	const form = document.createElement('form');
	form.style.display = 'none';
	document.body.appendChild(form);

	const fileInput = document.createElement('input');
	fileInput.multiple = true;
	fileInput.type = 'file';
	fileInput.addEventListener('change', function () {

		alert(fileInput.files[0]);
		editor.loader.loadFiles(fileInput.files);
		form.reset();

	});
	form.appendChild(fileInput);

	option = new UIRow();
	option.setClass('option');
	option.setTextContent(strings.getKey('menubar/file/import'));
	option.onClick(function () {

		fileInput.click();

	});
	//options.add( option );

	//

	*/

  // Import

  const form = document.createElement("form");
  form.style.display = "none";
  document.body.appendChild(form);

  const fileInput = document.createElement("input");
  fileInput.multiple = true;
  fileInput.type = "file";
  fileInput.accept = ".gltf";

  fileInput.addEventListener("change", function () {
    //alert(fileInput.files);
    editor.loader.loadFiles(fileInput.files);
    form.reset();
  });

  form.appendChild(fileInput);

  function addFileEditor(path) {
    const filePath = path;

    fetch(filePath)
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.blob();
      })
      .then((blob) => {
        const fileName = filePath.split("/").pop();
        const file = new File([blob], fileName, {
          type: "application/octet-stream",
        });
        editor.loader.loadFiles([file]);
      })
      .catch((error) => {
        console.error("Error loading file:", error);
      });
    form.reset();
  }

  const MonitorfileInput = new UIRow();
  MonitorfileInput.setClass("option");
  MonitorfileInput.setTextContent(strings.getKey("menubar/file/monimport"));
  MonitorfileInput.onClick(() => {
    editor.clear();
    const filePath = "example/SQL_Example4.2.fbx";
    const convertURLtoFile = (url) => {
      return fetch(url)
        .then((response) => response.blob())
        .then((data) => {
          //const ext = url.split(".").pop(); // 파일 확장자 추출
          const filename = url.split("/").pop(); // 파일 이름 추출
          const metadata = { type: "application/octet-stream" }; // FBX 파일의 MIME 타입
          return new File([data], filename, metadata);
        });
    };

    convertURLtoFile(filePath)
      .then((fbxFile) => {
        editor.loader.loadFile(fbxFile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    //editor.loader.loadFiles( filePath );

    window.setMonitorViewport();
  });

  window.addFileEditoronClick = addFileEditor;

  if (window.Viewmode === "Monitoring") {
    options.add(MonitorfileInput);
  }

  window.ImportGltfonClick = function () {
    fileInput.click();
  };

  option = new UIRow();
  option.setClass("option");
  option.setTextContent(strings.getKey("menubar/file/import"));
  option.onClick(function () {
    fileInput.click();
  });

  //

  if (window.Viewmode !== "Monitoring") {
    options.add(option);
  }
  options.add(new UIHorizontalRule());

  // Export DRC
  /*
	option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( strings.getKey( 'menubar/file/export/drc' ) );
	option.onClick( async function () {

		const object = editor.selected;

		if ( object === null || object.isMesh === undefined ) {

			alert( 'No mesh selected' );
			return;

		}

		const { DRACOExporter } = await import( 'three/addons/exporters/DRACOExporter.js' );

		const exporter = new DRACOExporter();

		const options = {
			decodeSpeed: 5,
			encodeSpeed: 5,
			encoderMethod: DRACOExporter.MESH_EDGEBREAKER_ENCODING,
			quantization: [ 16, 8, 8, 8, 8 ],
			exportUvs: true,
			exportNormals: true,
			exportColor: object.geometry.hasAttribute( 'color' )
		};

		// TODO: Change to DRACOExporter's parse( geometry, onParse )?
		const result = exporter.parse( object, options );
		saveArrayBuffer( result, 'model.drc' );

	} );
	//options.add( option );

	// Export GLB

	option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( strings.getKey( 'menubar/file/export/glb' ) );
	option.onClick( async function () {

		const scene = editor.scene;
		const animations = getAnimations( scene );

		const optimizedAnimations = [];

		for ( const animation of animations ) {

			optimizedAnimations.push( animation.clone().optimize() );

		}

		const { GLTFExporter } = await import( 'three/addons/exporters/GLTFExporter.js' );

		const exporter = new GLTFExporter();

		exporter.parse( scene, function ( result ) {

			saveArrayBuffer( result, 'scene.glb' );

		}, undefined, { binary: true, animations: optimizedAnimations } );

	} );
	//options.add( option );
	*/

  function exportGLTF() {
    const scene = editor.scene;
    let scenename = scene.name;

    if (scenename.indexOf(".gltf") === -1) {
      scenename += ".gltf";
    }

    const animations = getAnimations(scene);
    const optimizedAnimations = animations.map((animation) =>
      animation.clone().optimize()
    );

    import("three/addons/exporters/GLTFExporter.js").then(
      ({ GLTFExporter }) => {
        const exporter = new GLTFExporter();

        exporter.parse(
          scene,
          async function (result) {
            const jsonString = JSON.stringify(result, null, 2);

            try {
              if ("showSaveFilePicker" in window) {
                const handle = await window.showSaveFilePicker({
                  suggestedName: scenename,
                  types: [
                    {
                      description: "GLTF Files",
                      accept: { "model/gltf+json": [".gltf"] },
                    },
                  ],
                });

                const writable = await handle.createWritable();
                await writable.write(
                  new Blob([jsonString], { type: "model/gltf+json" })
                );
                await writable.close();
              } else {
                saveString(jsonString, scenename);
              }
            } catch (err) {
              console.error("Error saving file:", err);
            }
          },
          undefined,
          { animations: optimizedAnimations }
        );
      }
    );
  }

  // Export GLTF
  option = new UIRow();
  option.setClass("option");
  option.setTextContent(strings.getKey("menubar/file/export/gltf"));
  option.onClick(() => {
    exportGLTF;
  });

  window.ExportGltfonClick = exportGLTF;

  options.add(option);

  // Export OBJ
  /*
	option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( strings.getKey( 'menubar/file/export/obj' ) );
	option.onClick( async function () {

		const object = editor.selected;

		if ( object === null ) {

			alert( 'No object selected.' );
			return;

		}

		const { OBJExporter } = await import( 'three/addons/exporters/OBJExporter.js' );

		const exporter = new OBJExporter();

		saveString( exporter.parse( object ), 'model.obj' );

	} );
	//options.add( option );

	// Export PLY (ASCII)

	option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( strings.getKey( 'menubar/file/export/ply' ) );
	option.onClick( async function () {

		const { PLYExporter } = await import( 'three/addons/exporters/PLYExporter.js' );

		const exporter = new PLYExporter();

		exporter.parse( editor.scene, function ( result ) {

			saveArrayBuffer( result, 'model.ply' );

		} );

	} );
	//options.add( option );

	// Export PLY (Binary)

	option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( strings.getKey( 'menubar/file/export/ply_binary' ) );
	option.onClick( async function () {

		const { PLYExporter } = await import( 'three/addons/exporters/PLYExporter.js' );

		const exporter = new PLYExporter();

		exporter.parse( editor.scene, function ( result ) {

			saveArrayBuffer( result, 'model-binary.ply' );

		}, { binary: true } );

	} );
	//options.add( option );

	// Export STL (ASCII)

	option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( strings.getKey( 'menubar/file/export/stl' ) );
	option.onClick( async function () {

		const { STLExporter } = await import( 'three/addons/exporters/STLExporter.js' );

		const exporter = new STLExporter();

		saveString( exporter.parse( editor.scene ), 'model.stl' );

	} );
	//options.add( option );

	// Export STL (Binary)

	option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( strings.getKey( 'menubar/file/export/stl_binary' ) );
	option.onClick( async function () {

		const { STLExporter } = await import( 'three/addons/exporters/STLExporter.js' );

		const exporter = new STLExporter();

		saveArrayBuffer( exporter.parse( editor.scene, { binary: true } ), 'model-binary.stl' );

	} );
	//options.add( option );

	// Export USDZ

	option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( strings.getKey( 'menubar/file/export/usdz' ) );
	option.onClick( async function () {

		const { USDZExporter } = await import( 'three/addons/exporters/USDZExporter.js' );

		const exporter = new USDZExporter();

		saveArrayBuffer( await exporter.parse( editor.scene ), 'model.usdz' );

	} );
	//options.add( option );
	*/
  //

  function getAnimations(scene) {
    const animations = [];

    scene.traverse(function (object) {
      animations.push(...object.animations);
    });

    return animations;
  }

  return container;
}

export { MenubarFile };
