//import { UIPanel, UIButton, UIBreak, UIText, UIElement, UIDiv } from '@/components/assets/js/libs/ui.js';
import {
  UIPanel,
  UIButton,
  UIRow,
  UIText,
  UIClock,
  UIDiv,
  UINumber,
} from "@/components/assets/js/libs/ui.js";

//function ViewportInfo( editor ) {
function Toolbar(editor) {
  const signals = editor.signals;
  const strings = editor.strings;

  let container = new UIPanel();

  const playbarbackground = new UIDiv();
  playbarbackground.dom.style.backgroundColor = "rgba(10, 10, 10, 0.8)"; // 반투명 배경색
  playbarbackground.dom.style.borderRadius = "10px"; // 모서리 둥글게
  playbarbackground.dom.style.padding = "10px"; // 내부 여백 설정
  playbarbackground.dom.style.margin = "10px"; // 외부 여백 설정
  playbarbackground.dom.style.width = "auto"; // 너비 설정
  playbarbackground.dom.style.height = "50px"; // 높이 설정
  playbarbackground.dom.style.display = "flex"; // Flexbox 설정
  playbarbackground.dom.style.flexDirection = "row"; // 가로 정렬
  playbarbackground.dom.style.opacity = "0.3";

  playbarbackground.dom.addEventListener("mouseenter", function () {
    playbarbackground.dom.style.opacity = "1.0";
  });

  playbarbackground.dom.addEventListener("mouseleave", function () {
    playbarbackground.dom.style.opacity = "0.3";
  });

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////mixerTimeScaleRow
  const mixer = editor.mixer;
  const mixerTimeScaleRow = new UIRow();

  const mixerTimeScaleNumber = new UINumber(1)
    .setWidth("40px")
    .setRange(-100, 100);
  mixerTimeScaleNumber.dom.style.margin = "0px 5px"; // 위 아래 여백 5px, 좌 우 여백 10px

  mixerTimeScaleNumber.onChange(function () {
    mixer.timeScale = mixerTimeScaleNumber.getValue();
  });

  mixerTimeScaleRow.add(
    new UIText(strings.getKey("sidebar/animations/timescale")).setClass("Label")
  );
  mixerTimeScaleRow.add(mixerTimeScaleNumber);
  mixerTimeScaleRow.dom.style.margin = "5px 5px"; // 위 아래 여백 5px, 좌 우 여백 10px
  mixerTimeScaleRow.dom.style.width = "74px"; // 너비 설정
  /*
    mixerTimeScaleRow.dom.style.backgroundColor = 'rgba(255, 255, 255, 0.8)'; // 반투명 배경색
	*/

  playbarbackground.add(mixerTimeScaleRow);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const playIcon = document.createElement("img");
  playIcon.src = "images/play_button.svg";

  const playbutton = new UIButton();
  playIcon.style.height = "32px";
  playIcon.style.width = "32px";

  playbutton.dom.appendChild(playIcon);

  playbutton.dom.style.backgroundColor = "rgba(255, 255, 255, 0.0)";

  let intervalId = null;
  let intervalmat = null;
  let nowtimeobject = null;
  let issuelist = [];

  playbutton.onClick(function () {
    for (const object of editor.scene.children) {
      if (object !== null && object.animations.length > 0) {
        const animations = object.animations;
        for (const animation of animations) {
          const action = mixer.clipAction(animation, object);
          if (action.isRunning()) {
            action.paused = true;
            playIcon.src = "images/play_button.svg";
            clearInterval(intervalmat);
          } else {
            if (action.paused == true) {
              action.paused = false;
            } else {
              action.play();

              if (timerange == 0.0) {
                for (const object of editor.scene.children) {
                  if (object !== null && object.animations.length > 0) {
                    const animations = object.animations;
                    for (const animation of animations) {
                      const action = mixer.clipAction(animation, object);

                      if (timerange < action.getClip().duration) {
                        nowtimeobject = action;
                        timerange = action.getClip().duration;
                        window.timerange = timerange;
                      }
                    }
                  }
                }
              }
            }
            playIcon.src = "images/pause_button.svg";
          }
        }
      }
    }

    if (
      editor.scene.children.length > 0 &&
      editor.scene.children[0].userData !== undefined
    ) {
      issueevent();
      intervalId = setInterval(() => {
        mixerTimeSlider.value = (nowtimeobject.time / timerange) * 100;

        window.ClockSwitch = 1;
        clock.setValue(nowtimeobject.time);
        window.ClockSwitch = 0;

        window.nowtime = nowtimeobject.time;
      }, 100); // 0.1초마다 체크
    }
  });

  playbarbackground.add(playbutton);

  //////////////////////////////////////////////////////////////////////////////////////playbuttonRow



  
  function stoponclick() {
    mixer.stopAllAction();
    playIcon.src = "images/play_button.svg";

    mixerTimeSlider.value = 0;
    var event = new Event("input", {
      bubbles: true,
      cancelable: true,
    });
    mixerTimeSlider.dispatchEvent(event);
    if (intervalId !== null) {
      clearInterval(intervalId);
      clearInterval(intervalmat);
    }

    if (
      editor.scene.children.length > 0 &&
      editor.scene.children[0].userData !== undefined
    ) {
      if (Object.keys(editor.scene.children[0].userData).length !== 0) {
        const issues = editor.scene.children[0].userData.issue;
        if (Array.isArray(issues)) {
          for (const issue of issues) {
            let compobj = editor.scene.getObjectByName(issue.comp);
            if (compobj) {
              // compobj가 존재하는지 확인
              if (compobj.material) {
                if (compobj.material.name) {
                  if (compobj.material.name !== issue.material) {
                    compobj.material = editor.getMaterialByName(issue.material);
                  }
                }
              }
            } else {
              console.warn(
                `Object with name ${issue.comp} not found in the scene.`
              );
            }
          }
        } else {
          console.error(
            "editor.scene.children[0].userData.issue is not an array"
          );
        }
      }
    }


  }








  window.stoponClick = stoponclick;











  const stopIcon = document.createElement("img");
  //playIcon.title = strings.getKey( 'toolbar/translate' );
  stopIcon.src = "images/stop_button.svg";
  stopIcon.style.fill = "white"; // 흰색으로 설정
  const stopbutton = new UIButton();
  stopIcon.style.height = "32px";
  stopIcon.style.width = "32px";

  stopbutton.dom.appendChild(stopIcon);
  stopbutton.dom.style.backgroundColor = "rgba(255, 255, 255, 0.0)";
  stopbutton.dom.style.marginLeft = "5px";

  stopbutton.onClick(() => {
    stoponclick();
    
  });

  playbarbackground.add(stopbutton);

  /////////////////////////////////////////////////////////////////////Clock

  const clock = new UIClock(0).setWidth("100px");
  clock.setStyle("font-size", ["20px"]);
  clock.id = "AnimationClock";

  /////////////////////////////////////////////////////////////////////TimeSlider

  const mixerTimeSliderRow = new UIDiv();
  const mixerTimeSlider = document.createElement("input");
  mixerTimeSlider.type = "range";
  mixerTimeSlider.id = "TimeSlider";
  mixerTimeSlider.min = 0;
  mixerTimeSlider.max = 100;
  mixerTimeSlider.step = 0.1;
  mixerTimeSlider.value = 0;
  mixerTimeSlider.style.width = "120px";
  mixerTimeSlider.style.margin = "7px 10px"; // 위 아래 여백 5px, 좌 우 여백 10px
  mixerTimeSlider.style.left = "100px";
  let timerange = 0.0;
  window.ClockSwitch = 0;
  window.timerange = 0;

  mixerTimeSlider.addEventListener("input", function () {
    for (const object of editor.scene.children) {
      if (object !== null && object.animations.length > 0) {
        const animations = object.animations;
        for (const animation of animations) {
          const action = mixer.clipAction(animation, object);

          if (timerange < action.getClip().duration) {
            nowtimeobject = action;
            timerange = action.getClip().duration;
          }
        }
      }
    }
    window.timerange = timerange;
    window.nowtime = timerange * (parseFloat(mixerTimeSlider.value) / 100);

    for (const object of editor.scene.children) {
      if (object !== null && object.animations.length > 0) {
        const animations = object.animations;

        for (const animation of animations) {
          const action = mixer.clipAction(animation, object);
          action.time = window.nowtime;

          if (action.isRunning()) {
            action.play();
          } else {
            action.paused = true;
            action.play();
          }
        }
      }
    }
    if (window.ClockSwitch == 0) {
      window.ClockSwitch = 1;
      clock.setValue(
        Math.round(timerange * (parseFloat(mixerTimeSlider.value) / 100))
      );
      window.ClockSwitch = 0;
    }
  });

  mixerTimeSliderRow.dom.appendChild(mixerTimeSlider);

  playbarbackground.add(mixerTimeSliderRow);

  /////////////////////////////////////////////////////////////////////

  playbarbackground.add(clock);

  container.add(playbarbackground);

  container.setId("playerbar");
  container.setPosition("absolute");
  container.setBottom("30px");
  container.setFontSize("12px");
  container.setColor("#fff");
  container.setTextTransform("lowercase");

  signals.windowResize.add(function () {
    container.setLeft(
      document.getElementById("viewport").clientWidth / 2 - 220 + "px"
    );
  });
  function issueevent() {
    if (issuelist.length !== 0) {
      issuelist = [];
    }

    if (
      editor.scene.children.length > 0 &&
      editor.scene.children[0].userData !== undefined
    ) {
      if (Object.keys(editor.scene.children[0].userData).length !== 0) {
        if (Array.isArray(editor.scene.children[0].userData.issue)) {
          for (const issue of editor.scene.children[0].userData.issue) {
            issuelist.push([issue.time, issue.comp, issue.material]);
          }
        }
      }

      intervalmat = setInterval(() => {
        for (const issue of issuelist) {
          let compobj = editor.scene.getObjectByName(issue[1]);
          if (compobj && compobj.material) {
            if (window.nowtime > parseInt(issue[0]) &&
                window.nowtime < parseInt(issue[0]) + 5) {
              if (compobj.material.name === issue[2]) {
                compobj.material = editor.getMaterialByName("red");
              }
            } else {
              if (compobj.material.name !== issue[2]) {
                compobj.material = editor.getMaterialByName(issue[2]);
              }
            }
          }
        }
      }, 3000);
    }
  }

  if (window.Viewmode === "Monitoring") {
    container = new UIPanel();
  }

  return container;
}

export { Toolbar };

/*
import { UIPanel, UIButton, UICheckbox } from '@/components/assets/js/libs/ui.js';

function Toolbar( editor ) {

	const signals = editor.signals;
	const strings = editor.strings;

	const container = new UIPanel();
	container.setId( 'toolbar' );

	// translate / rotate / scale

	const translateIcon = document.createElement( 'img' );
	translateIcon.title = strings.getKey( 'toolbar/translate' );
	translateIcon.src = 'images/translate.svg';

	const translate = new UIButton();
	translate.dom.className = 'Button selected';
	translate.dom.appendChild( translateIcon );
	translate.onClick( function () {

		signals.transformModeChanged.dispatch( 'translate' );

	} );
	//container.add( translate );

	const rotateIcon = document.createElement( 'img' );
	rotateIcon.title = strings.getKey( 'toolbar/rotate' );
	rotateIcon.src = 'images/rotate.svg';

	const rotate = new UIButton();
	rotate.dom.appendChild( rotateIcon );
	rotate.onClick( function () {

		signals.transformModeChanged.dispatch( 'rotate' );

	} );
	//container.add( rotate );


	
	const scaleIcon = document.createElement( 'img' );
	scaleIcon.title = strings.getKey( 'toolbar/scale' );
	scaleIcon.src = 'images/scale.svg';

	const scale = new UIButton();
	scale.dom.appendChild( scaleIcon );
	scale.onClick( function () {

		signals.transformModeChanged.dispatch( 'scale' );

	} );
	//container.add( scale );

	const local = new UICheckbox( false );
	local.dom.title = strings.getKey( 'toolbar/local' );
	local.onChange( function () {

		signals.spaceChanged.dispatch( this.getValue() === true ? 'local' : 'world' );

	} );
	//container.add( local );
	
	//

	signals.transformModeChanged.add( function ( mode ) {

		translate.dom.classList.remove( 'selected' );
		rotate.dom.classList.remove( 'selected' );
		scale.dom.classList.remove( 'selected' );

		switch ( mode ) {

			case 'translate': translate.dom.classList.add( 'selected' ); break;
			case 'rotate': rotate.dom.classList.add( 'selected' ); break;
			case 'scale': scale.dom.classList.add( 'selected' ); break;

		}

	} );

	return container;

}

export { Toolbar };
*/
