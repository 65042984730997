import {
  UIPanel,
  UIRow,
  UISelect,
  UISpan,
  UIText,
  UIColor,
  UINumber,
} from "@/components/assets/js/libs/ui.js";
import { UITexture } from "./libs/ui.three.js";
import { SidebarSettingsShortcuts } from "./Sidebar.Settings.Shortcuts.js";
//import { SidebarSettingsHistory } from './Sidebar.Settings.History.js';

function SidebarSettings(editor) {
  const config = editor.config;
  const strings = editor.strings;
  const signals = editor.signals;

  const container = new UISpan();

  const settings = new UIPanel();
  settings.setBorderTop("0");
  settings.setPaddingTop("20px");
  container.add(settings);

  // language

  const options = {
    en: "English",
    ko: "한국어",
    fr: "Français",
    zh: "中文",
  };

  const languageRow = new UIRow();
  const language = new UISelect().setWidth("150px");
  language.setOptions(options);

  if (config.getKey("language") !== undefined) {
    language.setValue(config.getKey("language"));
  }

  language.onChange(function () {
    const value = this.getValue();

    editor.config.setKey("language", value);
  });

  languageRow.add(
    new UIText(strings.getKey("sidebar/settings/language")).setClass("Label")
  );
  languageRow.add(language);

  settings.add(languageRow);

  const themeoptions = {
    Dark: "Dark Theme",
    Light: "Light Theme",
  };
  const ThemeRow = new UIRow();
  const Theme = new UISelect().setWidth("150px");
  Theme.setOptions(themeoptions);

  window.threeThemeChange = function (value) {
    if (value === "Dark") {
      document.body.classList.add("dark-theme");
      document.body.classList.remove("light-theme");
      config.setKey("settings/theme", "dark");
    } else if (value === "Light") {
      document.body.classList.add("light-theme");
      document.body.classList.remove("dark-theme");
      config.setKey("settings/theme", "light");
    }
  };

  function applyTheme(theme) {
    if (theme === "Dark") {
      document.body.classList.add("dark-theme");
      document.body.classList.remove("light-theme");
      config.setKey("settings/theme", "Dark");
    } else if (theme === "Light") {
      document.body.classList.add("light-theme");
      document.body.classList.remove("dark-theme");
      config.setKey("settings/theme", "Light");
    }
  }

  Theme.onChange(function () {
    window.ThemeColor = Theme.getValue();
    applyTheme(window.ThemeColor);
  });

  if (config.getKey("settings/theme") !== undefined) {
    if (config.getKey("settings/theme") === "Dark") {
      Theme.setValue("Dark");
      applyTheme("Dark");
    } else if (config.getKey("settings/theme") === "Light") {
      Theme.setValue("Light");
      applyTheme("Light");
    }
  } else {
    Theme.setValue("Dark");
    applyTheme("Dark");
  }

  ThemeRow.add(
    new UIText(strings.getKey("sidebar/settings/theme")).setClass("Label")
  );
  ThemeRow.add(Theme);

  settings.add(ThemeRow);

  if (window.Viewmode === "WebViewer") {
    Theme.setValue("Dark");
    applyTheme("Dark");
  }

  //background

  const backcontainer = new UIPanel();

  const backgroundRow = new UIRow();

  const backgroundType = new UISelect()
    .setOptions({
      None: "",
      Color: "Color",
      Texture: "Texture",
      Equirectangular: "Equirect",
    })
    .setWidth("150px");
  backgroundType.onChange(function () {
    onBackgroundChanged();
    refreshBackgroundUI();
  });

  const backlabel = new UIText(
    strings.getKey("sidebar/scene/background")
  ).setClass("Label");
  backgroundRow.add(backlabel);
  backgroundRow.add(backgroundType);

  const backgroundColor = new UIColor()
    .setValue("#000000")
    .setMarginLeft("8px")
    .onInput(onBackgroundChanged);
  backgroundRow.add(backgroundColor);

  const backgroundTexture = new UITexture(editor)
    .setMarginLeft("8px")
    .onChange(onBackgroundChanged);
  backgroundTexture.setDisplay("none");
  backgroundRow.add(backgroundTexture);

  const backgroundEquirectangularTexture = new UITexture(editor)
    .setMarginLeft("8px")
    .onChange(onBackgroundChanged);
  backgroundEquirectangularTexture.setDisplay("none");
  backgroundRow.add(backgroundEquirectangularTexture);

  backcontainer.add(backgroundRow);

  const backgroundEquirectRow = new UIRow();
  backgroundEquirectRow.setDisplay("none");
  backgroundEquirectRow.setMarginLeft("120px");

  const backgroundBlurriness = new UINumber(0)
    .setWidth("40px")
    .setRange(0, 1)
    .onChange(onBackgroundChanged);
  backgroundEquirectRow.add(backgroundBlurriness);

  const backgroundIntensity = new UINumber(1)
    .setWidth("40px")
    .setRange(0, Infinity)
    .onChange(onBackgroundChanged);
  backgroundEquirectRow.add(backgroundIntensity);

  const backgroundRotation = new UINumber(0)
    .setWidth("40px")
    .setRange(-180, 180)
    .setStep(10)
    .setNudge(0.1)
    .setUnit("°")
    .onChange(onBackgroundChanged);
  backgroundEquirectRow.add(backgroundRotation);
  container.add(backgroundEquirectRow);

  function onBackgroundChanged() {
    signals.sceneBackgroundChanged.dispatch(
      backgroundType.getValue(),
      backgroundColor.getHexValue(),
      backgroundTexture.getValue(),
      backgroundEquirectangularTexture.getValue(),
      backgroundBlurriness.getValue(),
      backgroundIntensity.getValue(),
      backgroundRotation.getValue()
    );
  }

  function refreshBackgroundUI() {
    const type = backgroundType.getValue();

    backgroundType.setWidth(type === "None" ? "150px" : "110px");
    backgroundColor.setDisplay(type === "Color" ? "" : "none");
    backgroundTexture.setDisplay(type === "Texture" ? "" : "none");
    backgroundEquirectangularTexture.setDisplay(
      type === "Equirectangular" ? "" : "none"
    );
    backgroundEquirectRow.setDisplay(type === "Equirectangular" ? "" : "none");
  }

  container.add(backcontainer);
  container.add(new SidebarSettingsShortcuts(editor));
  //container.add( new SidebarSettingsShortcuts( editor ) );
  //container.add( new SidebarSettingsHistory( editor ) );

  window.backgroundColorChange = function (value) {
    backgroundType.setValue("Color");
    backgroundColor.setValue(value);
    onBackgroundChanged();
    refreshBackgroundUI();
  };

  if (window.Viewmode === "Monitoring") {
    backgroundType.setValue("Color");
    backgroundColor.setValue("#30872e");
    onBackgroundChanged();
    refreshBackgroundUI();
  }

  return container;
}

export { SidebarSettings };
