<!-- src/components/EmptyComponent.vue -->
<template>
  <div></div>
  <!-- 아무것도 표시하지 않도록 빈 div -->
</template>

<script>
export default {
  name: "EmptyComponent",
};
</script>
