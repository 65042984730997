<template>
  <div class="modal">
    <div
      class="fixed inset-0 bg-opacity-90 flex items-center justify-end z-50 pointer-events-none"
      style="bottom: -30px"
    >
      <div
        class="bg-gray-600 rounded-lg w-1/3 p-6 relative z-50 pointer-events-auto"
        style="height: 82vh; margin-right: 20px; overflow-y: auto"
      >
        <!-- Modal header -->
        <div class="flex justify-between items-center border-b pb-3">
          <h3 class="text-white text-lg font-semibold">이슈사항</h3>
          <button
            @click="closeModal"
            class="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 custom-x-btn"
          >
            X
          </button>
        </div>

        <!-- Modal body -->
        <div class="mt-4">
          <!-- Button actions -->
          <div class="flex justify-between mb-4 items-center">
            <!-- 왼쪽에 추가, 삭제 버튼 -->
            <div class="flex space-x-2">
              <button
                @click="addIssue"
                class="custom-orange text-white px-4 py-2 rounded"
              >
                추가
              </button>
              <button
                @click="confirmDelete"
                class="custom-orange text-white px-4 py-2 rounded"
                :disabled="issueData.length === 0"
              >
                삭제
              </button>

                              
            </div>
              <label class="custom-orange flex items-center space-x-2 text-gray-700 ml-auto rounded">
                <span class="text-white px-4 py-2">마킹</span>
                <input type="checkbox" v-model="MarkingCheck" class="form-checkbox" />
                <span class="text-white px-1 py-2"></span>
              </label>
          </div>

          <!-- Time buttons -->
          <div class="grid gap-4" style="grid-template-columns: 1fr 3fr">
            <!-- Left column (시간) -->
            <div class="border-r">
              <h4 class="text-white font-bold mb-2">시간</h4>
              <div
                class="overflow-y-auto"
                style="max-height: 550px; padding-right: 15px"
              >
                <div
                  v-for="(item, index) in issueData"
                  :key="index"
                  class="py-2 flex items-center justify-center"
                >
                  <button
                    @click="selectIssue(index)"
                    @dblclick="selectIssueDoubleClick(index)"
                    :class="{
                      'bg-blue-600 text-white': selectedIssueIndex === index,
                      'bg-gray-200 text-gray-700': selectedIssueIndex !== index,
                    }"
                    class="w-full px-4 py-2 rounded-lg hover:bg-blue-500 transition-colors duration-300"
                    style="box-sizing: border-box"
                  >
                    {{ item.name }}
                  </button>
                </div>
              </div>
            </div>

            <!-- Right column (내용) -->
            <div>
              <h4 class="text-white font-bold mb-2">내용</h4>
              <div v-if="selectedIssue">
                <form>
                  <div
                    class="w-full h-auto mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
                  >
                    <div
                      class="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800"
                    >
                      <label for="comment" class="sr-only">Your comment</label>
                      <textarea
                        id="comment"
                        v-model="selectedIssue.content"
                        class="w-full px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-dark dark:placeholder-gray-400"
                        placeholder="Write a comment..."
                        required
                        rows="20"
                        style="
                          overflow: hidden;
                          resize: none;
                          max-height: 360px;
                        "
                        @input="autoResize"
                        maxlength="407"
                      ></textarea>
                    </div>
                    <div
                      class="flex items-right bg-white px-3 py-2 border-t dark:border-gray-600"
                    >
                      <button
                        type="submit"
                        @click.prevent="saveIssue"
                        class="inline-flex items-right py-2.5 px-4 text-xs font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800"
                      >
                        저장
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /*
      issueData: window.winrefreshUIonClick() || [], // 기존 데이터를 불러옴

      selectedIssueIndex: -1, // 초기 선택 항목 없음
      showDeleteModal: false, // 삭제 확인 모달 상태
      */

      issueData: [], // 초기 데이터는 빈 배열
      loading: true, // 로딩 상태 추가
      selectedIssueIndex: -1,
      MarkingCheck: true, // 체크박스 상태 추가
      isModalVisible: false,
    };
  },
  computed: {
    selectedIssue() {
      return this.selectedIssueIndex !== -1
        ? this.issueData[this.selectedIssueIndex]
        : null;
    },
  },
  mounted() {
    // 처음 로드 시 첫 번째 시간 항목을 선택 (데이터가 있을 때만)
    window.refreshissue = this.refresh;
    this.loadData();
    if (this.issueData.length > 0) {
      this.selectIssue(0);
    }
  },
  methods: {
    async loadData() {
      try {
        this.issueData = await window.winrefreshUIonClick(); // 비동기 데이터 로딩
      } catch (error) {
        console.error("데이터 로드 중 오류 발생:", error);
      } finally {
        this.loading = false; // 로딩 완료
        if (this.issueData.length > 0) {
          this.selectIssue(0);
        }
      }
    },
    closeModal() {
      this.$emit("close");
    },
    selectIssue(index) {
      if (index >= 0 && index < this.issueData.length) {
        this.selectedIssueIndex = index;
        this.selectedIssue.content = window.IssueonClick(index);
      }
    },
    selectIssueDoubleClick(index) {
      if (index >= 0 && index < this.issueData.length) {
        this.selectedIssueIndex = index;
        window.IssueondblClick(index);
      }
    },
    saveIssue() {
      if (this.selectedIssueIndex !== -1) {
        window.IssueModifyonClick(
          this.selectedIssueIndex,
          this.selectedIssue.content
        );
      }
    },
    addIssue() {
      window.IssueaddonClick("",this.MarkingCheck);
      this.issueData = window.winrefreshUIonClick();

      this.selectIssue(this.issueData.length - 1);
    },
    confirmDelete() {


      window.myGlobalFunction("정말 삭제하시겠습니까?",0)
        .then((result) => {
          if (result) {
            
            this.isModalVisible = false; // 모달 닫기
            if (this.selectedIssueIndex !== -1) {
              window.IssueDeleteonClick(this.selectedIssueIndex);
              this.issueData = window.winrefreshUIonClick();
              this.showDeleteModal = false;
              // 삭제 후 첫 번째 항목을 선택
              if (this.issueData.length > 0) {
                this.selectIssue(0);
              } else {
                this.selectedIssueIndex = -1; // 선택된 항목 없음
              }
            }
            
          } 
        });
    
      
    },
   
    refresh() {
      this.issueData = window.winrefreshUIonClick();
      if (this.issueData.length > 0) {
        this.selectIssue(0);
      } else {
        this.selectedIssueIndex = -1; // 이슈가 없으면 선택된 이슈를 초기화
      }
    },
    // 텍스트 영역 자동 높이 조정
    autoResize(event) {
      const textarea = event.target;
      textarea.style.height = "auto"; // 초기화
      textarea.style.height = `${textarea.scrollHeight}px`; // 높이 재설정
    },
  },
};
</script>

<style scoped>
.modal {
  opacity: 0.96;
  width: 60%;
  max-height: 80vh;
  height: auto;
  max-width: 800px;
  overflow-y: auto;
}
.custom-x-btn {
  color: black;
}

.custom-orange {
  background-color: #fb923c;
  color: white;
}

.bg-gray-600 {
  background-color: #4b5563;
}

.text-white {
  color: #ffffff;
}

.bg-gray-300 {
  background-color: #d1d5db;
}

.bg-gray-400 {
  background-color: #9ca3af;
}

.bg-gray-200 {
  background-color: #e5e7eb;
}

.bg-blue-600 {
  background-color: #2563eb;
}

.bg-blue-500 {
  background-color: #3b82f6;
}

.text-gray-700 {
  color: #374151;
}

.bg-blue-700 {
  background-color: #1d4ed8;
}

.bg-blue-800 {
  background-color: #1e40af;
}

.text-gray-900 {
  color: #111827;
}

.bg-gray-50 {
  background-color: #f9fafb;
}

.bg-gray-800 {
  background-color: #1f2937;
}

.dark\:bg-gray-800 {
  background-color: #1f2937;
}

.dark\:bg-gray-700 {
  background-color: #374151;
}

.dark\:text-white {
  color: #ffffff;
}

.dark\:border-gray-600 {
  border-color: #4b5563;
}

.bg-red-500 {
  background-color: #ef4444;
}

.bg-white {
  background-color: #ffffff;
}

.text-center {
  text-align: center;
}

.overflow-y-auto {
  overflow-y: scroll;
}
</style>
