import { UIPanel } from '@/components/assets/js/libs/ui.js';
import * as pdfjsLib from 'pdfjs-dist/webpack';
function SidebarCompanyInfo(editor) {
    console.log(editor);

    const container = new UIPanel();
    container.setBorderTop('0');
    container.setPaddingTop('20px');
    container.setDisplay('');

    const pdfContainer = document.createElement('div');
    pdfContainer.style.width = '100%';
    pdfContainer.style.height = `${window.innerHeight - 160}px`;  
    pdfContainer.style.overflowY = 'scroll';  
    pdfContainer.style.position = 'relative'; 
    container.dom.appendChild(pdfContainer);

    const zoomInButton = document.createElement('button');
    zoomInButton.innerText = '확대';
    zoomInButton.style.marginRight = '10px';

    const zoomOutButton = document.createElement('button');
    zoomOutButton.innerText = '축소';

    container.dom.appendChild(zoomInButton);
    container.dom.appendChild(zoomOutButton);

    const pdfUrl = '../example/에스티앤아이 브로슈어.pdf';
    let canvases = []; // 렌더링된 캔버스 저장 배열
    let currentZoom = 1; // 기본 확대/축소 값
    let lastContainerWidth = 0; // 마지막 컨테이너 크기 저장
    let resizeTimeout = null; // 리사이징 타임아웃

    function renderPDF(url) {
        pdfjsLib.getDocument(url).promise.then(pdf => {
            console.log('PDF loaded');

            const numPages = pdf.numPages;
            pdfContainer.innerHTML = ''; // 기존 캔버스 제거
            canvases = []; // 기존 캔버스 배열 초기화

            for (let pageNum = 1; pageNum <= numPages; pageNum++) {
                pdf.getPage(pageNum).then(page => {
                    console.log(`Page ${pageNum} loaded`);

                    const containerWidth = pdfContainer.clientWidth;
                    const scale = containerWidth / page.getViewport({ scale: 1 }).width;
                    const viewport = page.getViewport({ scale: scale * 3 });

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.width = viewport.width;
                    canvas.height = viewport.height;

                    const aspectRatio = viewport.width / viewport.height;

                    page.render({
                        canvasContext: context,
                        viewport: viewport
                    });

                    canvas.style.width = '100%';
                    canvas.style.height = `${canvas.style.width / aspectRatio}px`; 

                    pdfContainer.appendChild(canvas);
                    canvases.push(canvas);
                });
            }
        }).catch(error => {
            console.error('Error loading PDF: ', error);
        });
    }

    function zoomCanvas(scale) {
        currentZoom *= scale;
        canvases.forEach(canvas => {
            const originalWidth = canvas.width / 2;
            const originalHeight = canvas.height / 2;
            canvas.style.width = `${originalWidth * currentZoom}px`;
            canvas.style.height = `${originalHeight * currentZoom}px`;
        });
    }

    const resizeObserver = new ResizeObserver(entries => {
        if (resizeTimeout) {
            clearTimeout(resizeTimeout); 
        }

        resizeTimeout = setTimeout(() => {
            entries.forEach(entry => {
                const containerWidth = entry.contentRect.width;

                if (Math.abs(containerWidth - lastContainerWidth) > 10) { 
                    canvases.forEach(canvas => {
                        const canvasAspectRatio = canvas.width / canvas.height;
                        const newCanvasWidth = containerWidth * currentZoom;
                        const newCanvasHeight = newCanvasWidth / canvasAspectRatio;

                        canvas.style.width = `${newCanvasWidth}px`;
                        canvas.style.height = `${newCanvasHeight}px`;
                    });

                    lastContainerWidth = containerWidth;
                }
            });
        }, 0);
    });

    resizeObserver.observe(pdfContainer);

    renderPDF(pdfUrl);

    zoomInButton.addEventListener('click', () => {
        zoomCanvas(1.1);  // 10% 확대
    });

    zoomOutButton.addEventListener('click', () => {
        zoomCanvas(0.9);  // 10% 축소
    });

    return container;
}


export { SidebarCompanyInfo };
